import React, { Component } from 'react'
//import SinchIndex from '../sinch/index';
export default class VideoModal extends Component {
    state = {
        anamnesis: this.props.anamnesis,
        show: this.props.show        
    }
    render() {
        return (<div className="video-call-popup video-call-popup-show">
            <div className="header">
                <span>KAMERA</span>
                <i className="icon-close" onClick={this.props.hideFunction}></i>
            </div>
            <div className="content">
            <iframe
                    title={this.props.src}
                    allowFullScreen
                    frameBorder="0"
                    height="100vh"
                    src={this.props.src}
                    width="100%"
                    allow="camera;microphone"
                />
            </div>
            <div className="bottom-bar">
                <div className="video">
                    <i className="icon-video-camera" onClick={this.props.hideFunction}></i>
                </div>
            </div>
        </div>
        )
  }
}



