import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from "./Footer"
import PageLoader from "./PageLoader";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import api from '../services/api';

const MySwal = withReactContent(Swal);

class Settings extends Component {
    state = {
        isPageLoader: true,
        user: {},
        attributes: {},
        tabs:{
            info: true,
            mail: false,
            mobile: false,
            education: false,
            about: false,
            document: false,
            career: false
        }            
    }
    componentDidMount(){
        var postData ={};
        postData.Scope = "language,doctorbranch,doctortitle,doctormaster,university"
        api.post('/api/GetAttributes', postData)
        .then(resp=> {
            for(var i = 0; i < resp.data.length; i++){
                this.setState({
                    [resp.data[i].Scope]: resp.data[i].Attributes
                })
            }
           
            api.post('/api/GetProfile?timestamp=' + new Date().getTime(), {})
            .then(resp=> {
                var attr = resp.data.Attributes;
                
                var doctorTitleCode = attr.filter(function(item){
                    return item.AttributeType === 3;         
                });
               
                console.log(doctorTitleCode[0])
                this.setState({
                    isPageLoader:false,
                    user: resp.data,
                    doctorTitleCode: doctorTitleCode[0]
                })
                
                console.log(resp.data)
            })
            .catch(function (error) {
                
            });
        })
        .catch(function (error) {
            if (error.response) {
                if(error.response.status === 400){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                    })
                }                
                else{
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
            }
        });

        
    }
    showTab = (tab)=>{
        var tabs = {
            info: false,
            mail: false,
            mobile: false,
            education: false,
            about: false,
            document: false,
            career: false
        }
        tabs[tab] = true;

        this.setState({
            tabs: tabs
        })
    }
    removeCareer = (id)=>{
        
    }
    render() {
        return (
            this.state.isPageLoader 
            ? <PageLoader />
            : <main className="clear-after overflow-hidden">
                <Header/>
                <section className="settings">
                    <div className="container">
                        <div className="bread-crumb"><i className="icon-setting"></i> <span>Ayarlar</span></div>

                        <div className="tab-dropdown-nav">
                            <div className="select-item">
                                <span data-tab-target="#info" className="select-text text-dark-grey">Bilgilerim</span>
                                <i className="icon-arrow-down"></i>
                            </div>
                            <ul className="list hide">
                                <li data-tab-target="#info" className="item text-dark-grey">Bilgilerim</li>
                                <li data-tab-target="#email" className="item text-dark-grey">E-Posta</li>
                                <li data-tab-target="#mobile" className="item text-dark-grey">Cep Telefonu</li>
                                <li data-tab-target="#career" className="item text-dark-grey">Kariyerim</li>
                                <li data-tab-target="#education" className="item text-dark-grey">Eğitim ve Uzmanlık</li>
                                <li data-tab-target="#about" className="item text-dark-grey">Hakkında Açıklama</li>
                                <li data-tab-target="#document" className="item text-dark-grey">Yüklü Evraklar</li>
                            </ul>
                        </div>
                        
                        <div className="side">
                            <div className="card">
                                <div className="tab-nav">
                                    <ul>
                                        <li onClick={() => this.showTab("info")} className="active">Bilgilerim</li>
                                        <li onClick={() => this.showTab("email")}>E-Posta</li>
                                        <li onClick={() => this.showTab("mobile")}>Cep Telefonum</li>
                                        <li onClick={() => this.showTab("career")}>Kariyerim</li>
                                        <li onClick={() => this.showTab("education")}>Eğitim ve Uzmanlık</li>
                                        <li onClick={() => this.showTab("about")}>Hakkında Açıklama</li>
                                        <li onClick={() => this.showTab("document")}>Yüklü Evraklar</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="body">
                            <div className="card">
                            {
                                this.state.tabs.info 
                                ? <div id="info" className="tab-setting active" data-tab-content>
                                    <section>
                                        <h1 className="title">BİLGİLERİM</h1>
                                        <div className="card-container">
                                            <div className="image">
                                                <img srcSet={this.state.user.ProfileImage} alt="Profil Resmi" />
                                                <div className="text"><Link to="/">Resmi Güncelle</Link></div>
                                            </div>

                                            <div className="form">
                                                <form>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Adınız</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <input type="text" className="general-input" defaultValue={this.state.user.FirstName} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Soyadınız</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <input type="text" className="general-input" defaultValue={this.state.user.FirstName} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="row">
                                                        <div className="label">
                                                            <span>Doğum Tarihiniz</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <input type="text" className="date-mask general-input" defaultValue={this.state.user.Birthdate} />
                                                        </div>
                                                    </div> */}
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Meslek Ünvan</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <i className="icon-arrow-down"></i>
                                                            <select className="general-input"  defaultValue={this.state.doctorTitleCode.Code}>
                                                            {
                                                                this.state.doctortitle.map(function(item){
                                                                    return(
                                                                    <option value={item.Code} key={item.Code}>{item.Name}</option>
                                                                    )
                                                                })
                                                            }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Cinsiyet</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <i className="icon-arrow-down"></i>
                                                            <select className="general-input"  defaultValue={this.state.user.Gender}>
                                                                <option value="0">Seçin</option>
                                                                <option value="1">Erkek</option>
                                                                <option value="2">Kadın</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="button">
                                                        <button>Kaydet</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                : null
                            }

                            {
                                this.state.tabs.email 
                                ? <div id="email" className="tab-setting active" data-tab-content>
                                    <section>
                                        <h1 className="title">E-POSTA DÜZENLE</h1>
                                        <div className="card-container">
                                            <div className="form">
                                                <form>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>E-Posta</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <input type="email" id="email" name="email" className="general-input" defaultValue={this.state.user.Email} />
                                                        </div>
                                                    </div>
                                                    <div className="button">
                                                        <button>Kaydet</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                :null 
                            }
                                
                            {
                                this.state.tabs.mobile 
                                ? <div id="mobile" className="tab-setting active" data-tab-content>
                                    <section>
                                        <h1 className="title">CEP TELEFONU DÜZENLE</h1>
                                        <div className="card-container">
                                            <div className="form">
                                                <form>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Cep Telefonu</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <input type="text" id="Mobile" name="Mobile" className="phone-mask general-input" defaultValue={this.state.user.Mobile} />
                                                        </div>
                                                    </div>
                                                    <div className="button">
                                                        <button>Kaydet</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                :null 
                            }
                                
                            {
                                this.state.tabs.career 
                                ? <div id="career" className="tab-setting active" data-tab-content>
                                    <section>
                                        <h1 className="title">Kariyerim </h1>
                                        <div className="card-container">
                                            <div className="add-button"><i className="icon-plus"></i> Ekle</div>

                                            <div className="form">
                                                <div className="list">
                                                    <div className="list-title">
                                                        Çalıştığım Kurumlar
                                                    </div>
                                                    <ul>
                                                        {
                                                            this.state.user.Careers.map((item,index) =>{
                                                                return(
                                                                    <li key={item.Id}><span>{item.CompanyName}</span>
                                                                    {/* <i className="icon-trash" onClick={()=> this.removeCareer(item.Id)}></i> */}
                                                                        <i className="" onClick={()=> this.removeCareer(item.Id)}>X</i>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                       
                                                    </ul>
                                                </div>
                                                <div className="button">
                                                    <button>Kaydet</button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                : null
                            }

                            {
                                this.state.tabs.education 
                                ? <div id="education" className="tab-setting active" data-tab-content>
                                    <section>
                                        <h1 className="title">Eğitim ve Uzmanlık</h1>
                                        <div className="card-container">
                                            <div className="form">
                                                <form>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Mezun Olduğunuz Üniversite:</span>
                                                        </div>
                                                        <div className="input">
                                                            <i className="icon-arrow-down"></i>
                                                            <select className="general-input">
                                                                <option>Amasya Üniversitesi Tıp Fakültesi</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>

                                    <section>
                                        <div className="card-container">
                                            <div className="add-button"><i className="icon-plus"></i> Ekle</div>

                                            <div className="form">
                                                <div className="list">
                                                    <div className="list-title">
                                                        Uzmanlık ve Akademik Eğitimler
                                                    </div>
                                                    <ul>
                                                        <li><span>Bilişim Uzmanı Master</span>
                                                            <i className="icon-trash"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section>
                                        <div className="card-container">
                                            <div className="form">
                                                <form>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Branş</span>
                                                        </div>
                                                        <div className="input">
                                                            <i className="icon-arrow-down"></i>
                                                            <select className="general-input">
                                                                <option>Aile Hekimi</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Yan Dal</span>
                                                        </div>
                                                        <div className="input">
                                                            <i className="icon-arrow-down"></i>
                                                            <select className="general-input">
                                                                <option>Seçiniz</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="button">
                                                        <button>Kaydet</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                : null                                    
                            }
                               
                            {
                                this.state.tabs.about
                                ? <div id="about" className="tab-setting active" data-tab-content>
                                    <section>
                                        <h1 className="title">Hakkınızda Kısa Açıklama</h1>
                                        <div className="card-container">
                                            <div className="form">
                                                <form>
                                                    <textarea rows="10" defaultValue={this.state.user.AboutText}></textarea>
                                                    <div className="button">
                                                        <button>Kaydet</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                : null
                            }

                            {
                                this.state.tabs.document 
                                ? <div id="document" className="tab-setting active" data-tab-content>
                                    <section>
                                        <h1 className="title">YÜKLÜ EVRAKLAR</h1>
                                        <div className="card-container">
                                            <div className="add-button"><i className="icon-plus"></i> Evrak Yükle</div>

                                            <div className="form">
                                                <div className="list">
                                                    <ul>
                                                        <li>
                                                            <i className="icon-paperclip"></i>
                                                            <div className="document">
                                                                <div className="line-title">Diploma</div>
                                                                <div className="line-date">09.12.2021</div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <i className="icon-paperclip"></i>
                                                            <div className="document">
                                                                <div className="line-title">Sertifika</div>
                                                                <div className="line-date">09.12.2021</div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div> 
                                : null    
                            }
                            </div>
                        </div>
                    </div>
                </section>
                <Footer isLogin={false}/>
            </main>
        )
    }
}
export default Settings;