import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import TokenService from "../services/token.service";

export default function Header(props) {
 

    const [status, setStatus] = useState({
        isLogin: false,
        redirect: false,
        user: {}        
    })



    
  const logout =  () => {
        TokenService.removeUser();
    }

    useEffect(() => {
        var loginUser = TokenService.getUser();
        if(loginUser === undefined  || loginUser === null || loginUser === "") return;

        setStatus({
            isLogin: true,
            user: loginUser
        })
    },[])
    
        return (
            <header className="header">
            <div className="top-header">
                <div className="container">
                    <div className="logo">
                        <Link to="/">
                            <img src="/images/dark-logo.png" alt="Logo" />
                        </Link>
                    </div>
                    <div className="items">
                        <ul>
                            <li><Link to="/examinations">Pano</Link></li>
                            <li><Link to="/settings">Ayarlar</Link></li>
                            <li><Link to="/profile" className="user"> 
                                <img srcSet={status.user.profileImage} alt={status.user.fullName} />
                                {status.user.fullName}</Link>
                            </li>
                            <li><Link to="/login" onClick={logout} ><i className="icon-turn-off"></i> Çıkış</Link></li>
                        </ul>
                    </div>
    
                    <div className="mobile-menu-bar">
                        <i className="icon-bar"></i>
                    </div>
                </div>
            </div>
            {props.content}
        </header>
        )
    
}
