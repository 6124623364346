import React, { Component } from "react";
import Loader from "../Loader";
import api from "../../services/api";
import ExaminationCard from "./ExaminationCard";

export default class ActiveExaminations extends Component {
	state = {
		isPageLoader: true,
		items: [],
		waitingItems: [],
	};
	componentDidMount() {
		api.post("/api/DoctorCurrentExaminationList", {}).then(resp => {
			this.setState({
				...this.state,
				items: resp?.data,
			});
		});
		api.post("/api/DoctorExaminationList", {}).then(resp => {
			this.setState({
				...this.state,
				waitingItems: resp?.data,
				isPageLoader: false,
			});
		});
	}
	render() {
		return this.state.isPageLoader ? (
			<section className="container-box ptb-100">
				<Loader />
			</section>
		) : (
			<div>
				{this.state.items?.length ? <div className="title text-primary">Bugün Yaklaşan</div> : null}
				{this.state.items.map(function (exam, index) {
					return <ExaminationCard key={index} {...exam} />;
				})}

				{this.state.waitingItems?.length ? (
					<div className="title text-orange">Bekleyen Randevu</div>
				) : null}
				{this.state.waitingItems.map(function (exam, index) {
					return <ExaminationCard key={index} {...exam} />;
				})}
			</div>
		);
	}
}
