import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

const emailPattern =
	/((^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$)|((05|5)[0-9][0-9][1-9]([0-9]){6}))/i;

const numberPattern = /([1-9][0-9]*)|0/;
//const MySwal = withReactContent(Swal);

function Login(props) {


	// Variables
	const form = useForm();



	return (
		<section className="splash">
			<div className="language">
				<Link to="/en">ENGLISH</Link>
			</div>

			<div className="col-left">
				<div className="container">
					<div className="logo">
						<img src="images/white-logo.png" alt="Logo" />
					</div>
					<div className="content">
						<img src="images/quotes.svg" alt="" style={{ marginLeft: -15 }} />
						<div className="title">Expectall ile Online Kolay</div>
						<div className="text">
							<p>
								Hastahane ve Tıp Merkezlerimizdeki doktorlarımızı görüntüleyip; Hemen ya da
								takvimden istediğiniz tarih ve saati seçerek hızlı randevu alabilirsiniz.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="col-right">
				<div className="form">
					<div className="container">
						<div className="title">DOKTOR GİRİŞİ</div>
						<form name="loginform">
							<div className="input-area">
								<label htmlFor="username">E-posta veya Cep Telefonu</label>
								<input
									
									{...form.register("username", {required:true, pattern: emailPattern | numberPattern})}
									type="text"
									placeholder="Mail adresiniz"
									
								/>
								<label htmlFor="password">Şifre</label>
								<input
									
									{...form.register("password", {required:true} )}
									type="password"
									placeholder="Şifreniz"
									
								/>
							</div>
							<div className="choose">
								<span>
									<label htmlFor="remember-me">
										<input type="checkbox" id="remember-me" {...form.register("remember-me")} />
										&nbsp; Beni Hatırla
									</label>
								</span>
								<span>
									<Link to="/forgot-password">Şifremi Unuttum</Link>
								</span>
							</div>
							<button
								type="submit"
								form="loginform"
									onClick={form.handleSubmit(props.onsubmit)}
								className={"splash-button btn-login"}
							>
								Giriş Yap
							</button>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Login;
