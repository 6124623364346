import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import { useParams } from "react-router-dom";
import api from "../services/api";
import ExaminationRequest from "./ExaminationRequest";
import ExaminationSummary from "./ExaminationSummary";
//import sinch from 'https://cdn.sinch.com/latest/sinch-rtc-min.js'

function ActiveExaminationRequests() {
	const [data, setData] = useState({
		isPageLoader: true,
		items: [],
	});

	const { id } = useParams();

	const renderSwitch = status => {
		switch (status) {
			case 1:
				return <ExaminationRequest data={data?.detail} examinationId={id} />;
			case 2:
				return <ExaminationSummary data={data?.detail} examinationId={id} />;
			default:
				return <ExaminationSummary data={data?.detail} examinationId={id} />;
		}
	};

	useEffect(() => {
		var postData = {};
		postData.ExaminationId = id;
		api
			.post("/api/GetExaminationSummaryForDoctor", postData)
			.then(resp => {
				setData({
					detail: resp?.data,
					isPageLoader: false,
				});
			})
			.catch(err => {});
	}, []);

	return data?.isPageLoader ? <Loader /> : renderSwitch(data?.detail?.ExaminationStatus);
}

export default ActiveExaminationRequests;
