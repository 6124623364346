import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useForm } from "react-hook-form";
import api from "../services/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

class ChangeMobile extends Component {
	state = {
		isPageLoader: true,
		videos: [],
	};
	componentDidMount() {
		var logindata = localStorage.getItem("logindata");
		var userData = JSON.parse(logindata);
	}
	render() {
		return (
			<main className="clear-after overflow-hidden">
				<Header />
				<section className="ptb-200 bg-xxlightgray color-themedark">
					<div className="container bg-white p-50 border border-color-xlightgray border-width-2">
						<h2 className="font-24 mb-20">Cep Telefonu Numarasını Güncelle</h2>
						<p className="font-16 lineheight-18">
							Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
							has been the industry's standard dummy text ever since the 1500s, when an unknown
							printer took a galley of type and scrambled it to make a type specimen book
						</p>
						<ChangeMobileForm />
					</div>
				</section>
				<Footer isLogin={false} />
			</main>
		);
	}
}
export default ChangeMobile;

export function ChangeMobileForm() {
	const [sendingForm, sendingToggle] = useState(false);
	const { register, handleSubmit, errors } = useForm();
	// const {register, handleSubmit, getValues, errors } = useForm();

	const onSubmit = data => {
		sendingToggle(true);
		api
			.post(window.apibaseurl + "/user/change_mobile", data, true)
			.then(resp => {
				MySwal.fire({
					html: <p>{resp.data.message}</p>,
					icon: "success",
					confirmButtonText: "Kapat",
				});
				sendingToggle(false);
			})
			.catch(function (error) {
				sendingToggle(false);
				if (error.response) {
					if (error.response.status === 400) {
						MySwal.fire({
							html: <p>{error.response.data.message}</p>,
							icon: "info",
							confirmButtonText: "Kapat",
						});
					} else {
						MySwal.fire({
							html: <p>{error.response.data.message}</p>,
							icon: "info",
							confirmButtonText: "Kapat",
						});
					}
				}
			});
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="changepassword-form ptb-50"
			autoComplete="off"
		>
			<div className="width-250">
				<div className="mtb-10">
					<label className="form-label font-14">Yeni Numara</label>
					<input
						type="text"
						id="current_password"
						name="current_password"
						className={
							"form-input form-input-large form-input-rounded" +
							(errors.current_password ? " error" : "")
						}
						ref={register({
							required: true,
						})}
					/>
				</div>

				<div className="mtb-30">
					<Link to="/account" className="font-w600 btn btn-white-o btn-rounded">
						<i className="fa fa-chevron-left"></i> Vazgeç
					</Link>
					<button
						type="submit"
						className={
							"font-w600 btn btn-black btn-rounded ml-30" + (sendingForm === true ? " sending" : "")
						}
					>
						Güncelle
					</button>
				</div>
			</div>
		</form>
	);
}
