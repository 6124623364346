import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ActiveExaminationRequests from "./ExaminatinsTab/ActiveExaminationRequests";
import ActiveExaminations from "./ExaminatinsTab/ActiveExaminations";
import CanceledExaminations from "./ExaminatinsTab/CanceledExaminations";
import CompletedExaminations from "./ExaminatinsTab/CompletedExaminations";
import SideInfo from "./SideInfo";
import AuthService from '../services/auth.service';
import { useNavigate } from "react-router-dom";

function Dashboard() {
	const [activeTab, setActiveTab] = useState(0);

	const selectTab = val => setActiveTab(val);
	const navigate = useNavigate()
	const currentUser = AuthService.getCurrentUser();

	useEffect(() => {
		if(!currentUser){
			navigate('/login')
		}
	},[currentUser,navigate])

	return (
		<main className="clear-after">
			<Header
				content={
					<div className="nav-header">
						<div className="container">
							<div className="title">
								<h1>GELEN MUAYENE TALEPLERİ</h1>
							</div>
							<div className="tab-nav">
								<ul>
									<li
										className={"text-primary" + (activeTab === 0 ? " active" : "")}
										onClick={() => selectTab(0)}
									>
										Aktif
									</li>
									<li
										className={"text-orange" + (activeTab === 1 ? " active" : "")}
										onClick={() => selectTab(1)}
									>
										Bekleyen Randevu
									</li>
									<li
										className={"text-dark-grey" + (activeTab === 2 ? " active" : "")}
										onClick={() => selectTab(2)}
									>
										İptal Edilen Randevu
									</li>
									<li
										className={"text-green" + (activeTab === 3 ? " active" : "")}
										onClick={() => selectTab(3)}
									>
										Tamamlanmış Randevu
									</li>
								</ul>
							</div>
							<div className="tab-dropdown-nav">
								<div className="select-item">
									<span data-tab-target="#active" className="select-text text-primary">
										Aktif
									</span>
									<i className="icon-arrow-down"></i>
								</div>
								<ul className="list hide">
									<li
										className={"item text-primary" + (activeTab === 0 ? " active" : "")}
										onClick={() => selectTab(0)}
									>
										Aktif
									</li>
									<li
										className={"item text-orange" + (activeTab === 1 ? " active" : "")}
										onClick={() => selectTab(1)}
									>
										Bekleyen Randevu
									</li>
									<li
										className={"item text-dark-grey" + (activeTab === 2 ? " active" : "")}
										onClick={() => selectTab(2)}
									>
										İptal Edilen Randevu
									</li>
									<li
										className={"item text-green" + (activeTab === 3 ? " active" : "")}
										onClick={() => selectTab(3)}
									>
										Tamamlanmış Randevu
									</li>
								</ul>
							</div>
						</div>
					</div>
				}
			/>

			<section className="inspection-requests">
				<div className="container">
					<div className="body">
						<div id="active" className={"tab-article" + (activeTab === 0 ? " active" : "")}>
							<ActiveExaminations />
						</div>
						<div id="pending" className={"tab-article" + (activeTab === 1 ? " active" : "")}>
							<ActiveExaminationRequests />
						</div>
						<div id="cancel" className={"tab-article" + (activeTab === 2 ? " active" : "")}>
							<CanceledExaminations />
						</div>
						<div id="complete" className={"tab-article" + (activeTab === 3 ? " active" : "")}>
							<CompletedExaminations />
						</div>
					</div>

					<div className="side">
						<SideInfo />
					</div>
				</div>
			</section>

			<Footer isLogin={false} />
		</main>
	);
}

export default Dashboard;
