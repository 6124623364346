const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refresh_token;
  };
  const getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.token_type + " " + user?.access_token;
  };
  const updateLocalAccessToken = (token_type, token) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.access_token = token;
    user.token_type = token_type;
    localStorage.setItem("user", JSON.stringify(user));
  };
  const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };
  const setUser = (data) => {
    // data.access_token = data.token_type + " " + data.token;

    // var name = data.user.name;y
    // var namesurname = data.user.name_surname;
    // if(name == null) name = "";
    // if(namesurname == null) namesurname = "";
    // data.user.name = name;
    // data.user.surname = namesurname.replace(name + " ", "");
    // data.user.name_surname = namesurname;

    localStorage.setItem("user", JSON.stringify(data));
  };
  const removeUser = () => {
    localStorage.removeItem("user");
  };
  const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
  };
  export default TokenService;