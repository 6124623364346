import React, { Component } from 'react'
import SquareImageViewer from "./SquareImageViewer";
import Loader from "./Loader";
import Header from "./Header";
import Footer from "./Footer";
import api from '../services/api';

let word;
export class MyList extends Component {
    state = {
        isLoading: true,
        videos: {}
    }
    componentDidMount (){
        word = decodeURIComponent(window.location.pathname.split('/')[2]);
        api.get(window.apibaseurl + '/v2/content/search?start=0&limit=20&q=' + word, true)
        .then(resp=> {
            console.log(resp.data)
            this.setState({
                videos: resp.data,
                isLoading: false
            })
        })
    }
    render() {
        return (
        <main className="clear-after">
            <Header/>
            { 
                this.state.isLoading 
                ? <section className="container-box pt-100">
                    <h3 className="font-22 color-white font-w500" >"{word}" <span className="font-18 font-w300 loading-dots">araması için sonuçlar yükleniyor</span></h3>
                    <div className="seperator seperator-darkgray mtb-20"></div>
                    <Loader />
                </section>
                : <section className="container-box pt-100">
                {this.state.videos.length === 0 
                 ? <h3 className="font-22 color-white font-w500">"{word}" <span className="font-18 font-w300">araması için hiç sonuç <u>bulunamadı</u>.</span></h3>
                 : <h3 className="font-22 color-white font-w500">"{word}" <span className="font-18 font-w300">araması için <b className="font-22 font-w500">{this.state.videos.length}</b> sonuç bulundu.</span></h3>
                }
                <div className="seperator seperator-darkgray mtb-20"></div>
                {this.state.videos.length > 0 
                 ? <div className="row row-30 column-count-3 row-s-0 mtb-30">
                    {
                    this.state.videos.map(video => {
                        const rand = 10000 + Math.random() * (99999 - 10000);
                        var parent_id = video.parent_id;
                        if(parent_id === undefined || parent_id === null){ 
                            parent_id = video.id;
                        }
                        if(video.video.length > 0){
                            return( 
                            <div className="col33 mb-60" key={video.id}>
                                <SquareImageViewer 
                                    key={video.id}
                                    id={video.id}
                                    parentId = {parent_id}
                                    rid={rand}
                                    title={video.title ?? ""}
                                    thumb={video.video[0].thumbnails["horizontal"]}
                                    videoUrl={video.video[0].url}
                                    detailUrl= {'/detail/' + video.id}
                                    preview={false}
                                />
                            </div>
                            )    
                        }
                        else{
                            return( 
                                
                                <div className="col33 mb-60" key={video.id}>
                                    <SquareImageViewer 
                                        key={video.id}
                                        id={video.id}
                                        rid = {0}
                                        parentId = {parent_id}
                                        title={video.title}
                                        thumb={"https://via.placeholder.com/733x446.png?text=" + video.title}
                                        videoUrl={""}
                                        detailUrl= {'/detail/' + video.id}
                                        preview={false}
                                    />
                                </div>
                            )    
                        }
                        
                    })
                }
                </div>
               : <div>
                    <p className="font-100 font-w500 text-center ptb-100">
                        :(
                    </p>
                </div>
            }
                </section>
            }
            <Footer isLogin={false}/>
        </main>
        )
    }
}
export default MyList