import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from "./Footer"
import PageLoader from "./PageLoader";
//import Swal from 'sweetalert2'
//import withReactContent from 'sweetalert2-react-content'
import api from '../services/api';

//const MySwal = withReactContent(Swal);

class ChangeMail extends Component {
    state = {
        isPageLoader: true,
        user: {},
        attributes: {}            
    }
    componentDidMount(){
        api.post('/api/GetProfile?timestamp=' + new Date().getTime(), {})
            .then(resp=> {
                var attr = resp.data.Attributes;
                
                var doctorTitleCode = attr.filter(function(item){
                    return item.AttributeType === 3;         
                });
                this.setState({
                    isPageLoader:false,
                    user: resp.data,
                    doctorTitleCode: doctorTitleCode[0]
                })
                
            })
            .catch(function (error) {
                
        });
        
    }
    render() {
        return (
            this.state.isPageLoader 
            ? <PageLoader />
            : <main className="clear-after overflow-hidden">
                <Header/>
                <section className="settings">
                    <div className="container">
                        <div className="bread-crumb"><i className="icon-setting"></i> <span>Ayarlar</span></div>

                        <div className="tab-dropdown-nav">
                            <div className="select-item">
                                <span data-tab-target="#info" className="select-text text-dark-grey">Bilgilerim</span>
                                <i className="icon-arrow-down"></i>
                            </div>
                            <ul className="list hide">
                                <li data-tab-target="#info" className="item text-dark-grey">Bilgilerim</li>
                                <li data-tab-target="#email" className="item text-dark-grey">E-Posta</li>
                                <li data-tab-target="#phone" className="item text-dark-grey">Cep Telefonu</li>
                                <li data-tab-target="#address" className="item text-dark-grey">Adresim</li>
                                <li data-tab-target="#career" className="item text-dark-grey">Kariyerim</li>
                                <li data-tab-target="#expertise" className="item text-dark-grey">Eğitim ve Uzmanlık</li>
                                <li data-tab-target="#about" className="item text-dark-grey">Hakkında Açıklama</li>
                                <li data-tab-target="#document" className="item text-dark-grey">Yüklü Evraklar</li>
                            </ul>
                        </div>
                        
                        <div className="side">
                            <div className="card">
                                <div className="tab-nav">
                                    <ul>
                                        <li data-tab-target="#info" className="active">Bilgilerim</li>
                                        <li data-tab-target="#email">E-Posta</li>
                                        <li data-tab-target="#phone">Cep Telefonum</li>
                                        <li data-tab-target="#address">Adresim</li>
                                        <li data-tab-target="#career">Kariyerim</li>
                                        <li data-tab-target="#expertise">Eğitim ve Uzmanlık</li>
                                        <li data-tab-target="#about">Hakkında Açıklama</li>
                                        <li data-tab-target="#document">Yüklü Evraklar</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="body">
                            <div className="card">
                                <div id="info" className="tab-setting active" data-tab-content>
                                    <section>
                                        <h1 className="title">BİLGİLERİM</h1>
                                        <div className="card-container">
                                            <div className="image">
                                                <img srcSet={this.state.user.ProfileImage} alt="Profil Resmi" />
                                                <div className="text"><Link to="#">Resmi Güncelle</Link></div>
                                            </div>

                                            <div className="form">
                                                <form>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Adınız</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <input type="text" className="general-input" defaultValue={this.state.user.FirstName} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Soyadınız</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <input type="text" className="general-input" defaultValue={this.state.user.FirstName} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="row">
                                                        <div className="label">
                                                            <span>Doğum Tarihiniz</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <input type="text" className="date-mask general-input" defaultValue={this.state.user.Birthdate} />
                                                        </div>
                                                    </div> */}
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Meslek Ünvan</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <i className="icon-arrow-down"></i>
                                                            <select className="general-input"  defaultValue={this.state.doctorTitleCode.Code}>
                                                            {
                                                                this.state.doctortitle.map(function(item){
                                                                    return(
                                                                    <option value={item.Code}>{item.Name}</option>
                                                                    )
                                                                })
                                                            }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Cinsiyet</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <i className="icon-arrow-down"></i>
                                                            <select className="general-input"  defaultValue={this.state.user.Gender}>
                                                                <option value="0">Seçin</option>
                                                                <option value="1">Erkek</option>
                                                                <option value="2">Kadın</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="button">
                                                        <button>Kaydet</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <div id="email" className="tab-setting" data-tab-content>
                                    <section>
                                        <h1 className="title">E-POSTA DÜZENLE</h1>
                                        <div className="card-container">
                                            <div className="form">
                                                <form>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>E-Posta</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <input type="email" id="email" name="email" className="general-input" defaultValue={this.state.user.email} />
                                                        </div>
                                                    </div>
                                                    <div className="button">
                                                        <button>Kaydet</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <div id="phone" className="tab-setting" data-tab-content>
                                    <section>
                                        <h1 className="title">CEP TELEFONU DÜZENLE</h1>
                                        <div className="card-container">
                                            <div className="form">
                                                <form>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Cep Telefonu</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="input">
                                                            <input type="text" id="Mobile" name="Mobile" className="phone-mask general-input" defaultValue={this.state.user.Mobile} />
                                                        </div>
                                                    </div>
                                                    <div className="button">
                                                        <button>Kaydet</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <div id="career" className="tab-setting" data-tab-content>
                                    <section>
                                        <h1 className="title">Kariyerim </h1>
                                        <div className="card-container">
                                            <div className="add-button"><i className="icon-plus"></i> Ekle</div>

                                            <div className="form">
                                                <div className="list">
                                                    <div className="list-title">
                                                        Çalıştığım Kurumlar
                                                    </div>
                                                    <ul>
                                                        <li><span>Acıbadem Hastahanesi</span>
                                                            <i className="icon-trash"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="button">
                                                    <button>Kaydet</button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <div id="expertise" className="tab-setting" data-tab-content>
                                    <section>
                                        <h1 className="title">Eğitim ve Uzmanlık</h1>
                                        <div className="card-container">
                                            <div className="form">
                                                <form>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Mezun Olduğunuz Üniversite:</span>
                                                        </div>
                                                        <div className="input">
                                                            <i className="icon-arrow-down"></i>
                                                            <select className="general-input">
                                                                <option>Amasya Üniversitesi Tıp Fakültesi</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>

                                    <section>
                                        <div className="card-container">
                                            <div className="add-button"><i className="icon-plus"></i> Ekle</div>

                                            <div className="form">
                                                <div className="list">
                                                    <div className="list-title">
                                                        Uzmanlık ve Akademik Eğitimler
                                                    </div>
                                                    <ul>
                                                        <li><span>Bilişim Uzmanı Master</span>
                                                            <i className="icon-trash"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section>
                                        <div className="card-container">
                                            <div className="form">
                                                <form>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Branş</span>
                                                        </div>
                                                        <div className="input">
                                                            <i className="icon-arrow-down"></i>
                                                            <select className="general-input">
                                                                <option>Aile Hekimi</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="label">
                                                            <span>Yan Dal</span>
                                                        </div>
                                                        <div className="input">
                                                            <i className="icon-arrow-down"></i>
                                                            <select className="general-input">
                                                                <option>Seçiniz</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="button">
                                                        <button>Kaydet</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <div id="about" className="tab-setting" data-tab-content>
                                    <section>
                                        <h1 className="title">Hakkınızda Kısa Açıklama</h1>
                                        <div className="card-container">
                                            <div className="form">
                                                <form>
                                                    <textarea rows="10" defaultValue={this.state.user.about}></textarea>
                                                    <div className="button">
                                                        <button>Kaydet</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <div id="document" className="tab-setting" data-tab-content>
                                    <section>
                                        <h1 className="title">YÜKLÜ EVRAKLAR</h1>
                                        <div className="card-container">
                                            <div className="add-button"><i className="icon-plus"></i> Evrak Yükle</div>

                                            <div className="form">
                                                <div className="list">
                                                    <ul>
                                                        <li>
                                                            <i className="icon-paperclip"></i>
                                                            <div className="document">
                                                                <div className="line-title">Diploma</div>
                                                                <div className="line-date">09.12.2021</div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <i className="icon-paperclip"></i>
                                                            <div className="document">
                                                                <div className="line-title">Sertifika</div>
                                                                <div className="line-date">09.12.2021</div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer isLogin={false}/>
            </main>
        )
    }
}
export default ChangeMail;