import React, { Component } from 'react'
import Loader from '../Loader';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import ExaminationCard from './ExaminationCard';
    
export default class CompletedExaminations extends Component {
    state = {
        isPageLoader: true,
        items:[]            
    }
    componentDidMount(){            
        api.post('/api/DoctorCompletedExaminationList', {})
        .then(resp=> {
            this.setState({
                isPageLoader: false,
                items: resp.data
            })
        });
    }
    render() {
        return (
            this.state.isPageLoader
            ? <section className="container-box ptb-100">
                <Loader />
            </section>
            : <div>
            <div className="title text-green">Tamamlanan</div>
            {
                this.state.items.map(function(exam, index) {
                    return <ExaminationCard key={index} {...exam} />
                })
            }
            
            <Link to="/examinations" className="all-see text-orange">Tümünü Gör (5 &gt;)</Link>
        </div>

        )
        
    }
}
