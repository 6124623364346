import React, { Component, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import api from '../services/api';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

let emailPattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
const MySwal = withReactContent(Swal);

export default class Register extends Component {
    render() {
        return (
            <main className="clear-after overflow-hidden">
                <section className="register-page pt-200">
                    <RegisterForm />
                </section>
            </main>
        )
    }
}


export function RegisterForm() {
    const [sendingForm, sendingToggle] = useState(false);
    const {register, handleSubmit, getValues, errors } = useForm();
    const navigate = useNavigate()
    
    const onSubmit = data => {
        data.phone = "+9" + data.phone;
        data.client_id = window.clientId;
        data.parent_id = window.parentId;
        data.phone_dial_code = "90";
        data.phone_iso2 = "TR";
        data.url = "https://www.semerkandseyir.com";
        data.mail_from_address = "noreply@semerkandseyir.com";
        data.mail_from_name = "SemerkandSeyir";
        sendingToggle(true);

        api.post(window.apibaseurl + '/auth/register', data, false
        ).then(resp=> {
            sendingToggle(false);
            MySwal.fire({
                html: <p>Kayıt işlemi başarılı, yönlendirilirken lütfen bekleyin.</p>,
                icon: 'success',
                showConfirmButton: false               
            })
            setTimeout(() => {
               navigate('/')
            }, 2000)
        })
        .catch(function (error) {
            sendingToggle(false);
            if (error.response) {
                if(error.response.status === 400 || error.response.status === 422){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
            }
        });
    }
     
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="register-form ptb-50 plr-30">
            <h1 className="font-30 font-w600 color-white text-center">Üye Ol</h1>
            <input id="name" name="name" type="text" ref={register({ required: true })} className={"form-input form-input-dark mtb-5" + (errors.name ? " error" : "")} placeholder="adınız" />
          
            <input id="surname" name="surname" type="text" className={"form-input form-input-dark mtb-5" + (errors.surname ? " error" : "")} placeholder="soyadınız"  
                ref={register({ 
                    required: true
                })}
            />
            <input id="email" name="email" type="email" ref={register({ required: true,  pattern: emailPattern })} className={"form-input form-input-dark mtb-5" + (errors.email ? " error" : "")} placeholder="Mail adresiniz" />
            <input id="phone" name="phone" type="text" className={"form-input form-input-dark mtb-5" + (errors.mobile ? " error" : "")} placeholder="Telefon numaranız" 
                 ref={register({ 
                     required: true, 
                     validate: {
                        matchesPreviousPassword: value => {
                            var pattern = /^5(0[5-7]|[3-5]\d) ?\d{3} ?\d{4}$/gm;
                            return !pattern.test(value) || "Geçerli bir cep numarası giriniz";
                        }
                    }
                })}
            />
            
            <input id="password" name="password" type="password" className={"form-input form-input-dark mtb-5" + (errors.password ? " error" : "")} placeholder="Şifreniz" 
                ref={register({ 
                    required: true
                })}
            />
            <input id="repassword" name="repassword" type="password" className={"form-input form-input-dark mtb-5" + (errors.repassword ? " error" : "")} placeholder="Şifreniz tekrar" 
                ref={register({ 
                    required: true,
                    validate: {
                        matchesPreviousPassword: value => {
                        const { password } = getValues();
                        return password === value || "Şifreler eşleşmiyor!";
                        }
                    }
                })}
            />
            {
            
            errors.passwordConfirmation && (
            <p style={{ color: "white" }}>
                {errors.passwordConfirmation.message}
            </p>
            )}
            <button type="submit" className={"btn btn-white btn-rounded width-max mt-20 font-w500" + (sendingForm === true ? " sending": "")}>Üye Ol</button>

            <p className="font-16 color-gray font-w400 text-center mt-30">
                Mevcut bir aboneliğiniz varsa 
                <br/>
                <Link to="/login" className="color-white">Şimdi giriş yapın</Link>
            </p>
        </form>
    );
  }