import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from "./Footer"
import { useForm } from "react-hook-form";
import api from '../services/api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import TokenService from '../services/token.service';

const MySwal = withReactContent(Swal);

class ChangeInfo extends Component {
    state = {
        isPageLoader: true,
        videos:[]            
    }
    componentDidMount(){
        var userData = TokenService.getUser();
        this.setState({
            name: userData.user.name,
            surname: userData.user.surname
        })
    }
    render() {
        return (
            <main className="clear-after overflow-hidden">
                <Header/>
                <section className="ptb-200 bg-xxlightgray color-themedark">
                    <div className="container bg-white p-50 border border-color-xlightgray border-width-2">
                        <h2 className="font-24 mb-20">İsmini Güncelle</h2>
                        <p className="font-16 lineheight-18">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                        </p>
                        <ChangeMobileForm name={this.state.name} surname={this.state.surname} />
                    </div>
                </section>
                <Footer isLogin={false}/>
            </main>
        )
    }
}
export default ChangeInfo;


export function ChangeMobileForm(props) {
    const name = props.name ?? "";
    const surname = props.surname ?? "";
    const [sendingForm, sendingToggle] = useState(false);
    const {register, handleSubmit, errors } = useForm();
   // const {register, handleSubmit, getValues, errors } = useForm();
    
    const onSubmit = data => {
        sendingToggle(true);
        
        var userData = TokenService.getUser();
        
        api.post('/user/change_name', data)
        .then(resp=> {
            userData.user.name = data.name;
            userData.user.surname = data.surname;
            userData.user.name_surname = data.name + " " + data.surname;
            TokenService.setUser(userData);

            MySwal.fire({
                html: <p>{resp.data.message}</p>,
                icon: 'success',
                confirmButtonText: 'Kapat'
            })
            sendingToggle(false);
        })
        .catch(function (error) {
            sendingToggle(false);
            if (error.response) {
                if(error.response.status === 400){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                    })
                }
                else {
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                    })
                }
            }
        });
    }
     
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="changepassword-form ptb-50" autoComplete="off">
            <div className="width-250">
                <div className="mtb-10">
                    <label className="form-label font-14">Adı</label>
                    <input type="text" id="name" name="name" defaultValue={name} className={"form-input form-input-large form-input-rounded" + (errors.name ? " error" : "")} 
                        ref={register({ 
                            required: true
                        })}
                    />
                </div>
                <div className="mtb-10">
                    <label className="form-label font-14">Soyadı</label>
                    <input type="text" id="surname" name="surname" defaultValue={surname} className={"form-input form-input-large form-input-rounded" + (errors.surname ? " error" : "")} 
                        ref={register({ 
                            required: true
                        })}
                    />
                </div>
                
                <div className="mtb-30">
                    <Link to="/account" className="font-w600 btn btn-white-o btn-rounded"><i className="fa fa-chevron-left"></i> Geri</Link>
                    <button type="submit" className={"font-w600 btn btn-black btn-rounded ml-30" + (sendingForm === true ? " sending": "")}>Güncelle</button>
                </div>
            </div>
        </form>
    );
  }