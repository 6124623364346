import React, { Component } from 'react'
import { Link } from 'react-router-dom';
    
export default class AnamnesisModal extends Component {
    constructor(props) {
        super(props);
        this.hideAnamnesis = this.hideAnamnesis.bind(this);
    }
    state = {
        anamnesis: this.props.anamnesis,
        show: this.props.show        
    }
    hideAnamnesis(){
        this.props.hideFunction();
    }
    render() {
        return (
            <div className={"request-popup" + (this.state.show ? " request-popup-show" : "")}>
                <div className="history">
                    <div className="close">Kapat</div>
            
                    <div className="title">Hastalık Geçmişi</div>
            
                    <div className="row">
                        <span className="property">Yaş: </span>
                        <span>{this.state.anamnesis.Age}</span>
                    </div>
                    <div className="row">
                        <span className="property">Cinsiyet: </span>
                        <span>{this.state.anamnesis.Gender}</span>
                    </div>
            
                    <div className="row">
                        <span className="property">Kan Grubu: </span>
                        <span>{this.state.anamnesis.BloodGroup}</span>
                    </div>

                    <div className="row">
                        <span className="property">Boy: </span>
                        <span>{this.state.anamnesis.Height}</span>
                    </div>

                    <div className="row">
                        <span className="property">Kilo: </span>
                        <span>{this.state.anamnesis.Weight}</span>
                    </div>

                    <div className="row">
                        <span className="property">Şikayet: </span>
                        <span>{this.state.anamnesis.Complaint}</span>
                    </div>
            
                    <div className="row">
                        <span className="property">Alışkalıklar: </span>
                        { this.state.anamnesis.Habits.Cigaret ?<span>Sigara</span>  : null }
                        { this.state.anamnesis.Habits.Alcohol ?<span>Alkol</span>  : null }
                        { this.state.anamnesis.Habits.Other ?<span>Diğer: {this.state.anamnesis.Habits.Other}</span>  : null }
                    </div>

                    <div className="row">
                        <span className="property">Alerji: </span>
                        <span>{this.state.anamnesis.Allergy}</span>
                    </div>

                    <div className="row">
                        <span className="property">1. Derece Akrabalarına Kronik Hastalıklar: </span>
                        <span>Şeker: {this.state.anamnesis.Diabetes}</span>
                        <span>Kalp Hastalıkları: {this.state.anamnesis.HeartDiseases}</span>
                        <span>Hipertansiyon: {this.state.anamnesis.Hypertension}</span>
                        <span>Diğer: {this.state.anamnesis.Other}</span>
                    </div>

                    <div className="row">
                        <span className="property">Geçirilmiş Hastalıklar:</span>
                        <span>{this.state.anamnesis.PastIllnesses}</span>
                    </div>

                    <div className="row">
                        <span className="property">Geçirilmiş Ameliyat:</span>
                        <span>{this.state.anamnesis.PastOperations}</span>
                    </div>

                    <div className="row">
                        <span className="property">Hastanede Yatma Öyküsü:</span>
                        <span>{this.state.anamnesis.Hospitalization}</span>
                    </div>

                    <div className="row">
                        <span className="property">Konulan Tanı: </span><br/>
                        <span>{this.state.anamnesis.Diagnosis}</span>
                    </div>
            
                    <div className="row">
                        <span className="property">Sürekli Kullandığı İlaçlar:</span>
                        <span>{this.state.anamnesis.DrugsUsed}</span>
                    </div>

                    <div className="row">
                        <span className="property">Meslek:</span>
                        <span>{this.state.anamnesis.Job}</span>
                    </div>

                    <div className="row">
                        <span className="property">Adres:</span>
                        <span>{this.state.anamnesis.Address}</span>
                    </div>

                    <div className="row">
                        <span className="property">Telefon:</span>
                        <span>{this.state.anamnesis.Phone}</span>
                    </div>

                </div>
            
                <div className="container">
                    <i className="icon-close close" onClick={this.hideAnamnesis}></i>
                    <div className="content">
                        <h1>ANAMNEZ: {this.state.anamnesis.Fullname}</h1>
                        <hr className="line" />
                        <div className="head">
                            <div className="user-info">
                                <div className="wrap">
                                    <img srcSet={"/images/users/user-" + this.state.anamnesis.Gender + ".png"} alt={this.state.anamnesis.Fullname} />
                                    <div className="informations">
                                        <div className="member">Üye:</div>
                                        <div className="name">{this.state.anamnesis.Fullname}</div>
                                        <div className="meeting">
                                            <i className="icon-chat"></i> <Link to="/examination">Soru Sor Görüşme Talebi</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="app-info">
                                <div className="app">Muayene</div>
                                <p>Hemen</p>
                            </div>
                        </div>
            
                        <hr className="line"/>
            
                        <table className="table">
                            <thead>

                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={5}>
                                        <span className="row-title">Hastanın İlettiği Anemnez</span>
                                    </td>
                                </tr>
                                {
                                    this.state.anamnesis.Age > 18 
                                    ? <tr className='border-bottom'>
                                        <td colSpan="2">
                                            <span className="property">Baba Adı: </span>
                                            <span className="value">{this.state.anamnesis.FatherName }</span>
                                        </td>
                                        <td colSpan="3">
                                            <span className="property">Anne Adı: </span>
                                            <span className="value">{this.state.anamnesis.MotherName}</span>
                                        </td>
                                    </tr>
                                    : null
                                }
                                <tr className='border-bottom'>
                                    <td colSpan="5">
                                        <span className="property">Hastanın Adı Soyadı: </span>
                                        <span className="value">{this.state.anamnesis.FullName}</span>
                                    </td>
                                </tr>    
                                <tr className="border-bottom">
                                    <td width="20%"><span className="property">Cinsiyet: </span>
                                        <span className="value">{this.state.anamnesis.Gender}</span>
                                    </td>
                                    <td width="20%"><span className="property">Yaş: </span>
                                        <span className="value">{this.state.anamnesis.Age}</span>
                                    </td>
                                    <td width="20%"><span className="property">Kilo: </span>
                                        <span className="value">{this.state.anamnesis.Wieght}</span>
                                    </td>
                                    <td width="20%"><span className="property">Boy: </span>
                                        <span className="value">{this.state.anamnesis.Height}</span>
                                    </td>
                                    <td width="20%"><span className="property">Kan Grubu: </span>
                                        <span className="value">{this.state.anamnesis.BloodGroup}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span className="row-title">Hastanın Şikayeti</span></td>
                                </tr>
                                <tr className="border-bottom">
                                    <td colSpan="5">
                                        <p>{this.state.anamnesis.Complaint}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5">
                                        <span className="row-title">Alışkanlıklar</span>
                                    </td>
                                </tr>
                                <tr className="border-bottom">
                                    <td colSpan="5">
                                    { this.state.anamnesis.Habits.Cigaret ? <span className="item"><i className="icon-check"></i> Sigara</span> : null }
                                    { this.state.anamnesis.Habits.Alcohol ? <span className="item"><i className="icon-check"></i> Alkol</span> : null }
                                    { this.state.anamnesis.Habits.Other ? <span className="item"><i className="icon-check"></i> {this.state.anamnesis.Habits.Other}</span> : null }
                                       
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5">
                                        <span className="row-title">Alerji Hikayesi</span>
                                    </td>
                                </tr>
                                <tr className="border-bottom">
                                    <td colSpan="5">
                                        <p>{ this.state.anamnesis.Allergy }</p>
                                    </td>
                                </tr>
                                <tr className="border-bottom">
                                    <td colSpan="5">
                                        <span className="row-title">1. Derece Akrabalarında Kronik Hastalıklar</span>
                                    </td>
                                </tr>
                                <tr className="border-bottom">
                                    <td colSpan="5">
                                        { this.state.anamnesis.DiseasesOfRelatives.Diabetes ? <span className="item"><i className="icon-check"></i> Diabet</span> : null }
                                        { this.state.anamnesis.DiseasesOfRelatives.Hypertension ? <span className="item"><i className="icon-check"></i> Hiper Tansiyon</span> : null }
                                        { this.state.anamnesis.DiseasesOfRelatives.HeartDiseases ? <span className="item"><i className="icon-check"></i> Kalp Hastalıkları</span> : null }
                                        { this.state.anamnesis.DiseasesOfRelatives.Other ? <span className="item"><i className="icon-check"></i> {this.state.anamnesis.DiseasesOfRelatives.Other}</span> : null }
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5">
                                        <span className="row-title">Geçirilmiş Hastalıklar</span>
                                    </td>
                                </tr>
                                <tr className="border-bottom">
                                    <td colSpan="5">
                                        {/* <!-- <span className="item"><i className="icon-check"></i> Hastalık</span> --> */}
                                        <span className="item">{this.state.anamnesis.PastIllnesses}</span>
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td colSpan="5">
                                        <span className="row-title">Hastalık Geçmişi</span>
                                    </td>
                                </tr>
                                <tr className="border-bottom">
                                    <td colSpan="5">
                                        <span className="item rounded-item history-item">Kovid <i className="icon-right-arrow"></i></span>
                                    </td>
                                </tr> */}
                                <tr>
                                    <td colSpan="5">
                                        <span className="row-title">Geçirilmiş Ameliyatlar</span>
                                    </td>
                                </tr>
                                <tr className="border-bottom">
                                    <td colSpan="5">
                                        {/* <!-- <span className="item"><i className="icon-check"></i> Ameliyat</span> --> */}
                                        <span className="item">{this.state.anamnesis.PastOperations}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5">
                                        <span className="row-title">Hastahanede Yatma Öyküsü</span>
                                    </td>
                                </tr>
                                <tr className="border-bottom">
                                    <td colSpan="5">
                                        <p>{this.state.anamnesis.Hospitalization}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5">
                                        <span className="row-title">Sürekli Kullandığı İlaçlar</span>
                                    </td>
                                </tr>
                                <tr className="border-bottom">
                                    <td colSpan="5">
                                        <p>{this.state.anamnesis.DrugsUsed}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" valign='top'>
                                        <span className="property">Meslek: </span>
                                        <span className="value">{ this.state.anamnesis.Job }</span> <br/> <br/>
                                        <span className="property">Telefonu: </span>
                                        <span className="value">{ this.state.anamnesis.Phone }</span>
                                    </td>
                                    <td colSpan="3" valign='top'>
                                        <span className="property">Adres: </span>
                                        <span className="value">{ this.state.anamnesis.Address }</span>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </div>
                </div>
            
                <div className="footer">
                    <div className="time">
                    </div>
                    <div className="buttons">
                        <button className="close" onClick={this.hideAnamnesis}>Kapat</button>
                    </div>
                </div>
            </div>

        )
        
    }
}