import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Dashboard from "./components/Dashboard";
import LoginWrapper from "./components/LoginWrapper";
import Register from "./components/Register";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import TermsOfUse from "./components/TermsOfUse";
import Settings from "./components/Settings";
import Profile from "./components/Profile";
import Faq from "./components/Faq";
import ChangePassword from "./components/ChangePassword";
import ChangeMobile from "./components/ChangeMobile";
import ChangeMail from "./components/ChangeMail";
import ChangeInfo from "./components/ChangeInfo";
import SearchResult from "./components/SearchResult";
import Premium from "./components/Premium";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { BrowserView, MobileView } from "react-device-detect";
import TokenService from "./services/token.service";
import Examinations from "./components/Examinations";
import Examination from "./components/Examination";

//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

function App() {
	const [premium, setPremium] = useState(false);
	const [expireAt, setExpireAt] = useState(0);

	const authProps = { premium, expireAt };

	useEffect(() => {
		var logindata = TokenService.getUser();
		if (logindata !== undefined && logindata !== null && logindata !== "") {
			setPremium(true);
		}

		if (!logindata & (window.location.pathname !== "/login")) {
			window.location.pathname = "/login";
		}
	}, []);

	return (
		<BrowserRouter>
			<div className="app">
				<BrowserView>
					<div id="main">
						<Routes>
							<Route
								path="/"
								exact
								element={
									premium === true ? <Dashboard auth={authProps} /> : <Premium auth={authProps} />
								}
							/>

							<Route element={<LoginWrapper />} path="/login" />
							<Route element={<Register />} path="/register" />
							<Route element={<TermsOfUse />} path="/terms-of-use" />
							<Route element={<Faq />} path="/sss" />
							<Route element={<PrivacyPolicy />} path="/privacy-policy" />
							<Route element={<ForgotPassword />} path="/forgot-password" />
							<Route element={<ResetPassword />} path="/reset-password/:token" />
							<Route element={<Examinations />} path="/examinations" />
							<Route element={<Examination />} path="/examination/:id" />
							<Route
								path="/settings"
								element={
									premium === true ? <Settings auth={authProps} /> : <Premium auth={authProps} />
								}
							/>

							<Route
								path="/profile"
								element={
									premium === true ? <Profile auth={authProps} /> : <Premium auth={authProps} />
								}
							/>

							<Route
								path="/search-result/:word"
								element={
									premium === true ? (
										<SearchResult auth={authProps} />
									) : (
										<Premium auth={authProps} />
									)
								}
							/>

							<Route
								path="/change-password"
								element={
									premium === true ? (
										<ChangePassword auth={authProps} />
									) : (
										<Premium auth={authProps} />
									)
								}
							/>

							<Route
								path="/change-mobile"
								element={
									premium === true ? (
										<ChangeMobile auth={authProps} />
									) : (
										<Premium auth={authProps} />
									)
								}
							/>
							<Route
								path="/change-mail"
								element={
									premium === true ? <ChangeMail auth={authProps} /> : <Premium auth={authProps} />
								}
							/>

							<Route
								path="/change-info"
								element={
									premium === true ? <ChangeInfo auth={authProps} /> : <Premium auth={authProps} />
								}
							></Route>
						</Routes>
					</div>
				</BrowserView>
				<MobileView>
					<div className="display-t width-max height-100vh">
						<div className="text-center display-tc valign-middle">
							<img src="/Assets/images/logo.png" alt="Semerkan Seyir" />
							<h1 className="font-24 font-w400 mt-20">Uygulamayı Hemen İndir!</h1>
							<h2 className="font-18 font-w400 color-theme2 mb-20">
								Çok daha rahat bir şekilde seyret
							</h2>
							<Link
								to="https://apps.apple.com/tr/app/semerkand-seyir/id1589931890?l=tr"
								className="display-ib mr-5"
								target={"_blank"}
								ref={"noopener noreferrer"}
							>
								<img
									srcSet="/Assets/images/appstore-logo.png"
									alt="Semerkand Seyir Apple Appstore"
								/>
							</Link>
							<Link
								to="https://play.google.com/store/apps/details?id=com.semerkand.seyir&hl=tr&gl=US"
								className="display-ib ml-5"
								target={"_blank"}
								ref={"noopener noreferrer"}
							>
								<img
									srcSet="/Assets/images/google-logo.png"
									alt="Semerkand Seyir Google Play Store"
								/>
							</Link>
						</div>
					</div>
				</MobileView>
			</div>
		</BrowserRouter>
	);
}

export default App;
