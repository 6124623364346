import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import TokenService from "./token.service";

var baseURL = "//api.drpir.com/api";
//var baseURL= "https://localhost:44345"
var headers = {
	"Content-Type": "application/x-www-form-urlencoded",
	Authorization: TokenService.getLocalAccessToken(),
};

const MySwal = withReactContent(Swal);

const acceptExamination = ExaminationId => {
	const data = { ExaminationId, Lat: 0, Lon: 0 };

	//var postData = JSON.stringify({data});
	const postData = new URLSearchParams(data).toString();
	return axios
		.post(baseURL + "/AcceptExaminationRequest", postData, { headers })
		.then(response => {
			return response;
		})
		.catch(function (error) {
			console.log(error);
			if (error.response) {
				if (error.response.status === 400) {
					MySwal.fire({
						html: <p>{error.response.error_description}</p>,
						icon: "info",
						confirmButtonText: "Kapat",
					});
				}
			}
		});
};

const rejectExamination = ExaminationId => {
	const data = { ExaminationId, Lat: 0, Lon: 0 };

	//var postData = JSON.stringify({data});
	const postData = new URLSearchParams(data).toString();
	return axios
		.post(baseURL + "/RejectExaminationRequest", postData, { headers })
		.then(response => {
			return response;
		})
		.catch(function (error) {
			console.log(error);
			if (error.response) {
				if (error.response.status === 400) {
					MySwal.fire({
						html: <p>{error.response.error_description}</p>,
						icon: "info",
						confirmButtonText: "Kapat",
					});
				}
			}
		});
};

const ExaminationService = { acceptExamination, rejectExamination };

export default ExaminationService;
