import React, { Component } from 'react'

export default class Timer extends Component {
    constructor(props) {
      super();
      this.state = { time: {}, seconds: props.RemainingSecond };
      this.timer = 0;
      this.startTimer = this.startTimer.bind(this);
      this.countDown = this.countDown.bind(this);
    }
  
    secondsToTime(secs){
      let hours = Math.floor(secs / (60 * 60));
  
      let divisor_for_minutes = secs % (60 * 60);
      let minutes = Math.floor(divisor_for_minutes / 60);
  
      let divisor_for_seconds = divisor_for_minutes % 60;
      let seconds = Math.ceil(divisor_for_seconds);
  
      let obj = {
        "h": hours,
        "ht": hours > 9 ? hours : "0" + hours,
        "m": minutes,
        "mt": minutes > 9 ? minutes : "0" + minutes,
        "s": seconds,
        "st": seconds > 9 ? seconds : "0" + seconds,
      };
      return obj;
    }
  
    componentDidMount() {
      let timeLeftVar = this.secondsToTime(this.state.seconds);
      this.setState({ time: timeLeftVar });
      this.startTimer();
    }
  
    startTimer() {
      if (this.timer === 0 && this.state.seconds > 0) {
        this.timer = setInterval(this.countDown, 1000);
      }
    }
  
    countDown() {
      // Remove one second, set state so a re-render happens.
      let seconds = this.state.seconds - 1;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });
      
      // Check if we're at zero.
      if (seconds === 0) { 
        clearInterval(this.timer);
      }
    }
  
    render() {
      return(
        this.state.hour === 0 
        ? <span>{this.state.time.mt}:{this.state.time.st}</span>
        : <span>{this.state.time.ht}:{this.state.time.mt}:{this.state.time.st}</span>
      );
    }
  }
  