

import React from 'react'
import { useForm } from "react-hook-form";
function SmsCode(props) {
    const form = useForm()


  return (
    <section class="splash">
    <div class="col-left">
        <div class="container">
            <div class="logo">
                <img src="images/white-logo.png" alt="Logo" />
            </div>
        </div>
    </div>

    <div class="col-right">
        <div class="back"><a href="login.html"><i class="icon-arrow-back"></i> Geri</a></div>

        <div class="form">
            <div class="container">
                <div class="title">SMS KODU <br /> <span>TELEFONUNUZA GELEN SMS KODUNU GİRİN</span></div>
                <form action="login.html" onSubmit={form.handleSubmit(props.onsubmit)}>
                    <div class="input-area">
                        <div class="verify-code-input">
                            <input type="number" class="verify-code" min="0" max="9" {...form.register('one', {required:true, maxLength:1})} required />
                            <input type="number" class="verify-code" min="0" max="9" {...form.register('two', {required:true})} required />
                            <input type="number" class="verify-code" min="0" max="9" {...form.register('three', {required:true})} required />
                            <input type="number" class="verify-code" min="0" max="9" {...form.register('four', {required:true})} required />
                        </div>
                        <button type="submit" class="splash-button" >ONAYLA</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
  )
}

export default SmsCode








