import React, { Component } from 'react'
import PropTypes from "prop-types";

let delayHandler;
class SquareImageViewer extends Component {
    getBoundingRect = (element) => {
        var style = window.getComputedStyle(element); 
        var margin = {
            left: parseInt(style["margin-left"]),
            right: parseInt(style["margin-right"]),
            top: parseInt(style["margin-top"]),
            bottom: parseInt(style["margin-bottom"])
        };
        var padding = {
            left: parseInt(style["padding-left"]),
            right: parseInt(style["padding-right"]),
            top: parseInt(style["padding-top"]),
            bottom: parseInt(style["padding-bottom"])
        };
        var border = {
            left: parseInt(style["border-left"]),
            right: parseInt(style["border-right"]),
            top: parseInt(style["border-top"]),
            bottom: parseInt(style["border-bottom"])
        };
        
        var rect = element.getBoundingClientRect();
        rect = {
            left: rect.left - margin.left,
            right: rect.right - margin.right - padding.left - padding.right,
            top: rect.top - margin.top,
            bottom: rect.bottom - margin.bottom - padding.top - padding.bottom - border.bottom  
        };
        rect.width = rect.right - rect.left;
        rect.height = rect.bottom - rect.top;
        return rect;
    }
    handleMouseEnter = (e) =>{
        var domRect = this.getBoundingRect(e.target.parentNode);
        var left = domRect.left;
        var width = domRect.width;
        var height = domRect.height + 80;
        var top = domRect.top + window.pageYOffset;

        delayHandler = setTimeout(() => {
            var content = document.getElementById("video-preview-" + this.props.rid);
            var previewModal = document.createElement("div");
            previewModal.innerHTML = content.innerHTML;
            previewModal.setAttribute("id" , "video-previewmodal-" + this.props.rid);
            previewModal.classList = "description";

            if(previewModal === null ||previewModal === undefined) return;

            previewModal.onmouseenter = function(e){e.preventDefault();};
            previewModal.onmouseleave = function(e){e.target.parentNode.removeChild(e.target)};
            previewModal.style.display = "block";
            
            var newWidth = width * 1.2;
            var ratio = newWidth / width;
            var newHeight = height * ratio;
            var widthDiff = (newWidth - width) / 2;
            var heightDiff = (newHeight - height) / 2;

            previewModal.style.width =  newWidth + "px";
            previewModal.style.height = newHeight + "px";
            previewModal.style.left = (left - widthDiff) + "px"; 
            previewModal.style.top = (top - heightDiff) + "px";
            
            var transform = ""
            if(left + newWidth > window.innerWidth){
                previewModal.style.transformOrigin = 'right center';
                previewModal.style.left = (left - (widthDiff * 2)) + "px"; 
            }
            else if(left - widthDiff < 0){
                previewModal.style.left = left + "px"; 
                previewModal.style.transformOrigin = 'left center';
            }
            previewModal.style.transform = transform;

            previewModal.setAttribute("id" , "video-previewmodal-" + this.props.rid)
            document.body.appendChild(previewModal);
            //var video = document.querySelector("#video-preview-" + this.props.id + " video");
            //video.play();  
            setTimeout(() => {
                previewModal.style.transform = transform + " scale(1)";
            }, 10)
        }, 500)
    }
    handleMouseLeave = () => {
        clearTimeout(delayHandler)
    }
    render() {
        return (
            <div id={"video-" + this.props.rid} ref={this.videoRef} className="display-b movie" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                <img alt="" className="responsive-image" src={this.props.thumb} ref={img => this.img = img} onError={
                    () => this.img.src = "https://via.placeholder.com/733x446.png?text="+this.props.title
                }/>
               
            </div>
        )
    }
}
SquareImageViewer.propTypes = {
    title: PropTypes.string.isRequired,
    thumb: PropTypes.string.isRequired
}
export default SquareImageViewer;