const initialState = {
 user: null
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'USER_LOGIN':
      console.log(action)
      return {
        user: action.payload
      };
    case 'USER_LOGOUT':
     
      return {
        user:null
      };

    default:
      return state;
  }
}