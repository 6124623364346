import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
//import axios from 'axios'

export default function Premium() {

        return (
        <main className="clear-after">
            <Header/>
                <section className="container-box pt-100 pt-lg-150">
                    <div className="container-box clear-after">
                        <div className="col60">
                            <img src="/Assets/images/VodDevices.png" alt="" className="responsive-image" />
                        </div>
                        <div className="col40 pl-20">
                            <h2 className="font-24 fontw-500">Dilediğiniz zaman, dilediğiniz yerde seyredin.</h2>
                            <p className="mt-20 font-18 lineheight-15">
                            Semerkand Seyir’in temiz içerikler dünyasını keşfetmek için şimdi kaydolabilirsiniz.
                            </p>
                            <Link to="/register" className="btn btn-yellow btn-large mt-40 font-w800 font-24 color-white hover-color-black border-radius-5">Abone Ol</Link>
                            <p className="mt-40">
                                <span className="font-36 font-w600 display-ib">₺20 <span className="font-14 font-w300">Aylık üyelik</span></span>
                                <span className="font-36 font-w600 display-ib ml-50">₺209,<sup className="font-24">99</sup> <span className="font-14 font-w300">Yıllık üyelik</span></span>
                                <span className="font-14 color-gray display-b pt-10">
                                Ekstra maliyet yok, taahhüt yok. <br/>
                                <Link to="/terms-of-use" className="color-yellow">Şartlar ve Koşullar</Link>.
                                </span>
                            </p>
                        </div>
                    </div>
                </section> 
            <Footer isLogin={false}/>
        </main>
        )
    
}
