import React, { Component, useState } from 'react'
import api from '../services/api';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);
let token = "";
class ResetPassword extends Component {
    componentDidMount (){
        token = this.props.match.params.token;
    }
    render() {
        return (
            <main className="clear-after overflow-hidden">
                <section className="register-page ptb-200">
                    <ResetPasswordForm />
                </section>
            </main>
        )
    }
}
export default ResetPassword;


export function ResetPasswordForm() {
    const [sendingForm, sendingToggle] = useState(false);
    const {register, handleSubmit, getValues, errors } = useForm();
    const navigate = useNavigate()

    const onSubmit = data => {
        sendingToggle(true);
        data.token = token;
        api.post(window.apibaseurl + '/auth/reset_password', data, false)
        .then(resp=> {
            console.log(resp)
            MySwal.fire({
                html: <p>{resp.data.message}</p>,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonText: 'Giriş Sayfasına Git',
                allowOutsideClick: false              
            }).then((result) => {
                if (result.isConfirmed) {
                    MySwal.fire({
                        html: <p>Giriş sayfasına yönlendirilirken lütfen bekleyin.</p>,
                        icon: 'success',
                        showConfirmlButton: false,
                        showCancelButton: false,
                        allowOutsideClick: false  
                        
                    })
                    navigate('/login')

                }
            });
        })
        .catch(function (error) {
            sendingToggle(false);
            if (error.response) {
                if(error.response.status === 400){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
                else if(error.response.status === 422){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        showConfirmlButton: true,
                        confirmButtonText: 'Kapat'
                       
                    });
                }
                else if(error.response.status === 404){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
                else{
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
            }
        });
    }
     
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="register-form ptb-50 plr-30">
            <h1 className="font-30 font-w600 color-white text-center mb-10">Şifre Sıfırla</h1>
            <input id="password" name="password" type="password" autoComplete="on" className={"form-input form-input-dark mtb-5" + (errors.password ? " error" : "")} placeholder="Şifreniz" 
                ref={register({ 
                    required: true
                })}
            />
            <input id="password_confirmation" name="password_confirmation" type="password" autoComplete="on" className={"form-input form-input-dark mtb-5" + (errors.password_confirmation ? " error" : "")} placeholder="Şifreniz tekrar" 
                ref={register({ 
                    required: true,
                    validate: {
                        matchesPreviousPassword: value => {
                        const { password } = getValues();
                        return password === value || "Şifreler eşleşmiyor!";
                        }
                    }
                })}
            />
            <button type="submit" className={"btn btn-white btn-rounded width-max mt-20 font-w500" + (sendingForm === true ? " sending": "")}>Gönder</button>
        </form>
    );
  }