import { useState } from "react";
import SmsCode from "./SmsCode";
import Login from "./Login";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";

function LoginWrapper() {
	const [prevData, setData] = useState(null);

	const navigate = useNavigate();

	// Actions
	const onSubmitLogin = data => {
		AuthService.login(data.username, data.password, undefined).then(
			resp => {
				if (resp) {
					setTimeout(() => {
						navigate("/");
						window.location.reload();
					}, 2000);
				}
			},
			error => {
				if (error?.response?.data?.error === "verification_code_required") {
					setData(data);
				}
			}
		);

		// return;
	};

	const onSubmitSms = data => {
		const { one, two, three, four } = data;

		AuthService.smsCodeVerify(prevData.username, prevData.password, `${one}${two}${three}${four}`)
			.then(resp => {
				if (resp) {
					setTimeout(() => {
						navigate("/");
					}, 2000);
				} else {
					console.log("Res: ", resp);
				}
			})
			.catch(err => {
				console.log("sms verify err: ", err);
			});
	};

	return prevData ? <SmsCode onsubmit={onSubmitSms} /> : <Login onsubmit={onSubmitLogin} />;
}

export default LoginWrapper;
