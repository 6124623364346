import React, { Component } from "react";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import Header from "./Header";
import AnamnesisModal from "./AnamnesisModal";
import VideoCallModal from "./VideoCallModal";

let totalAmount = 0;
//const monthNameList = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
export default class ExaminationSummary extends Component {
	constructor(props) {
		super(props);
		this.showAnamnesis = this.showAnamnesis.bind(this);
		this.hideAnamnesis = this.hideAnamnesis.bind(this);
		this.showVideoCall = this.showVideoCall.bind(this);
		this.hideVideoCall = this.hideVideoCall.bind(this);
	}
	state = {
		isPageLoader: true,
		detail: { ...this.props.data },
		showAnamnesis: false,
		showVideoCall: false,
		appointmentDate: new Date(),
	};

	componentDidMount() {
		this.setState({
			isPageLoader: false,
		});
	}
	showAnamnesis() {
		this.setState({
			showAnamnesis: true,
		});
	}
	hideAnamnesis() {
		this.setState({
			showAnamnesis: false,
		});
	}
	showVideoCall() {
		this.setState({
			showVideoCall: true,
		});
	}
	hideVideoCall() {
		this.setState({
			showVideoCall: false,
		});
	}

	getStatus() {
		const status = this.state.detail.ExaminationStatus;
		switch (status) {
			case 1:
				return "Talep aşamasında";
			case 2:
				return "Doktorun kabul etti";
			case 3:
				return "Reddedilmiş";
			case 4:
				return "Süresi geçmiş";
			case 5:
				return "Tamamlanmış";
			case 6:
				return "Mola verilmiş";
			case 7:
				return "Başlamış";
			case 8:
				return "Hasta onayı bekleniyor";
			default:
				return "Tanımsız";
		}
	}

	isCancellable() {
		const status = this.state.detail.ExaminationStatus;
		return [1, 2, 8].includes(status);
	}

	render() {
		return this.state.isPageLoader ? (
			<section className="container-box ptb-100">
				<h3 className="font-22 color-white hover-color-theme2 font-w500 mb-20">
					Bu hafta öne çıkanlar
				</h3>
				<div className="seperator seperator-theme3 mb-30"></div>
				<Loader />
			</section>
		) : (
			<div>
				<section className="app-detail">
					<Header></Header>
					<div className="container">
						<div className="bread-crumb">
							<Link to="/">Anasayfa &gt;</Link> {this.getStatus()}
						</div>
						<div className="body">
							<article>
								<div className="wrap">
									<img
										srcSet={this.state.detail.ProfileImageFull}
										alt={this.state.detail.FullName}
									/>
									<div className="informations">
										<div className="member">Üye</div>
										<div className="name">
											{this.state.detail.FirstName + " " + this.state.detail.LastName}
										</div>
										{(() => {
											switch (this.state.detail.ExaminationType) {
												case 2:
													return (
														<div className="meeting btn-chat">
															<i className="icon-video-camera"></i>{" "}
															<span>Video Görüşme Talebi</span>
														</div>
													);
												case 3:
													return (
														<div className="meeting btn-chat">
															<i className="icon-chat"></i> <span>Soru Sor Görüşme Talebi</span>
														</div>
													);
												default:
													return null;
											}
										})()}
									</div>
								</div>
							</article>

							<article>
								<div className="row-wrap">
									<div className="info">
										<div className="status">
											<span className="property">Durum:</span>
											<span className="value text-green">
												<i className="icon-date"></i> <span>{this.getStatus()}</span>
											</span>
											{/* <span className="value text-orange"><i className="icon-date"></i> <span>Bekleyen Randevu</span></span> */}
										</div>
										<div className="date-time">
											<div className="date">
												<span className="property">Randevu Tarihi:</span>{" "}
												<span className="value">11 Mart 2022</span>
											</div>
											<div className="time">
												<span className="property">Saati:</span>{" "}
												<span className="value">11:00 - 11:30</span>
											</div>
										</div>
									</div>
									<div className="button">
										<Link className="anamnesis" value="1">
											Hastanın Anemnezini Gör &gt;
										</Link>
									</div>
								</div>
							</article>
							{this.state.detail.ExaminationStatus === 2 ? (
								<article>
									<div className="head">
										<div className="title">Tanısı</div>
										<div className="button">
											<button className="fade">
												<i className="icon-plus"></i> Tanı Koy
											</button>
										</div>
									</div>
									<div className="content"></div>
								</article>
							) : null}

							<article>
								<div className="title">Ödeme Bilgileri</div>
								<div className="fees">
									{this.state.detail.Payments?.map((item, index) => {
										totalAmount += item.Amount;
										return (
											<div className="row" key={index}>
												<span>Muayene Ücreti</span>
												<span>{item.Amount} TL</span>
											</div>
										);
									})}

									<hr className="line" />
									<div className="row">
										<span>Toplam</span> <span>{totalAmount} TL</span>
									</div>
								</div>
							</article>
						</div>

						<div className="side">
							{this.state.detail.ByAppopment}

							{this.isCancellable() && (
								<div className="card">
									<div className="title">Randevunuz</div>
									<div className="cancel">
										<Link>İptal Et</Link>
									</div>
								</div>
							)}

							<div className="card">
								<div className="title">İşlem</div>

								<div className="row">
									<i className="icon-video-camera"></i>
									<button className="btn-video fade" onClick={this.showVideoCall}>
										Görüntülü Ara
									</button>
								</div>

								<div className="row">
									<i className="icon-chat"></i>
									<button className="btn-chat fade">Sohbet</button>
								</div>
							</div>
						</div>
					</div>
				</section>
				{this.state.showAnamnesis ? (
					<AnamnesisModal
						anamnesis={this.state.detail.Anamnesis}
						show={this.state.showAnamnesis}
						hideFunction={this.hideAnamnesis}
					/>
				) : null}
				{this.state.showVideoCall ? (
					<VideoCallModal
						src={
							"https://api.drpir.com/call/" +
							this.state.detail.HealthProfessionalId +
							"/a9714560-a571-453a-adc1-adfa00768978?" +
							this.state.detail.CustomerId
						}
						hideFunction={this.hideVideoCall}
					/>
				) : null}
			</div>
		);
	}
}
