import api from "./api";
import TokenService from "./token.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

var authorization = "BASIC RE9UTkVUOkVFRjQ3RDlBLURCQTktNEQwMi1CN0IwLTA0RjQyNzlBNkQyMA==";
var baseURL = "//api.drpir.com";
//var baseURL= "https://localhost:44345"
var headers = {
	"Content-Type": "application/x-www-form-urlencoded",
	Authorization: authorization,
};

const MySwal = withReactContent(Swal);

const register = (username, email, password) => {
	return api.post(baseURL + "/register", {
		username,
		email,
		password,
	});
};
const login = (username, password) => {
	var data = {};
	data.password = password;
	data.username = username;
	data.grant_type = "password";
	data.device_id = "chrome";
	data.device_name = "chrome";
	data.device_type = "pc";
	data.device_model = "chrome";
	data.platform = "web";
	data.culture = "2";

	//var postData = JSON.stringify({data});
	const postData = new URLSearchParams(data).toString();
	return axios
		.post(baseURL + "/token", postData, { headers: headers })
		.then(response => {
			if (response.status === 200) {
				TokenService.setUser(response.data);
				MySwal.fire({
					html: <p>Giriş işlemi başarılı, yönlendirilirken lütfen bekleyin.</p>,
					icon: "success",
					showConfirmButton: false,
					timer: 2000,
				});
				return true;
			}

			MySwal.fire({
				html: <p>{response.error_description}</p>,
				icon: "info",
				confirmButtonText: "Kapat",
			});
			return false;
		})
		.catch(function (error) {
			if (error.response) {
				if (
					error.response.status === 400 &&
					error?.response?.data?.error !== "invalid_verification_code"
				) {
					MySwal.fire({
						html: <p>{error.response.error_description}</p>,
						icon: "info",
						confirmButtonText: "Kapat",
					});
				}
			}

			return false;
		});
};
const smsCodeVerify = (username, password, code) => {
	var data = {};
	data.password = password;
	data.username = username;
	data.grant_type = "password";
	data.device_id = "chrome";
	data.device_name = "chrome";
	data.device_type = "pc";
	data.device_model = "chrome";
	data.platform = "web";
	data.culture = "2";
	data.verification_code = code;

	//var postData = JSON.stringify({data});
	const postData = new URLSearchParams(data).toString();
	return axios
		.post(baseURL + "/token", postData, { headers: headers })
		.then(response => {
			if (response.status === 200) {
				MySwal.fire({
					html: <p>Giriş işlemi başarılı, yönlendirilirken lütfen bekleyin.</p>,
					icon: "success",
					showConfirmButton: false,
					timer: 2000,
				});

				return true;
			} else {
				MySwal.fire({
					html: <p>{response.error_description}</p>,
					icon: "info",
					confirmButtonText: "Kapat",
				});
				return false;
			}
		})
		.catch(function (error) {
			console.log(error);
			if (error.response) {
				if (error.response.status === 400) {
					MySwal.fire({
						html: <p>{error.response.error_description}</p>,
						icon: "info",
						confirmButtonText: "Kapat",
					});
				}
			}
		});
};
const logout = () => {
	TokenService.removeUser();
};
const getCurrentUser = () => {
	return JSON.parse(localStorage.getItem("user"));
};
const AuthService = {
	register,
	login,
	logout,
	getCurrentUser,
	smsCodeVerify,
};
export default AuthService;
