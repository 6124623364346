import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import api from '../services/api';
import Loader from './Loader';

class SideInfo extends Component {
    state = {
        isPageLoader: true,
        user: {},
    }
    componentDidMount(){
        api.post('/api/GetDashboard?timestamp=' + new Date().getTime(), {})
        .then(resp=> {
            this.setState({
                isPageLoader:false,
                user: resp.data
            })
            
            console.log(resp.data)
        })
        .catch(function (error) {
            
        });
    }
    render() {
        return (
            this.state.isPageLoader 
            ? <Loader />
            : <div className="card">
            <div className="wrap">
                <img srcSet={this.state.user.ProfileImage} alt="Op.Dr. Yeşim Ballıgız" />
                <div className="info">
                    <div className="name">
                        {this.state.user.FirstName} {this.state.user.LastName}
                    </div>
                    <div className="title">
                        {this.state.user.Title}
                    </div>
                    <div className="value">
                        <i className="icon-star"></i> <span>{this.state.user.AverageScore}</span> {this.state.user.Comments.length} Değerlendirme
                    </div>
                </div>
            </div>

            <hr className="line" />

            <div className="status">
                <div className="title">
                    Durum
                </div>
                <div className="text">
                    Muayene 
                    {
                        (()=> {
                        switch(this.state.user.LiveStatus){
                            case 1:return(<span>Açık</span>)
                            case 2:return(<span>Kapalı</span>)
                            default: return(<span></span>)
                        }})()
                    }
                    
                </div>
            </div>

            <hr className="line" />

            <div className="settings">
               
                <div className="title">Muayene Görüşme Türü Ayarları</div>
                <div className="meeting">
                    <div className="text"><i className="icon-chat"></i> Sohbet Görüşmesi</div> 
                    {
                        this.state.user.ExaminationTypeList.filter((item)=> { return (item === 3)} ).length > 0 ?<span>Aktif</span>:<span className='color-red'>Pasif</span>
                    }
                </div>

                <hr className="line" />

                <div className="meeting">
                    <div className="text"><i className="icon-video-camera"></i> Görüntülü Görüşme</div>
                    {
                        this.state.user.ExaminationTypeList.filter((item)=> { return (item === 2)} ).length > 0 ?<span>Aktif</span>:<span className='color-red'>Pasif</span>
                    }
                </div>
            </div>
            <hr/>
            <Link to="/profile" className="peak-settings"><i className="icon-edit"></i> Muayene Ayarları</Link>
        </div>
        )
    }
}
export default SideInfo;