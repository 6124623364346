import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Timer from "./Timer";
import AnamnesisModal from "./AnamnesisModal";
import VideoCallModal from "./VideoCallModal";
import ExaminationService from "../services/examination.service";

//import SinchIndex from '../sinch/index';
const monthNameList = [
	"Ocak",
	"Şubat",
	"Mart",
	"Nisan",
	"Mayıs",
	"Haziran",
	"Temmuz",
	"Ağustos",
	"Eylül",
	"Ekim",
	"Kasım",
	"Aralık",
];
export default class ExaminationRequest extends Component {
	constructor(props) {
		super(props);
		this.showAnamnesis = this.showAnamnesis.bind(this);
		this.hideAnamnesis = this.hideAnamnesis.bind(this);
		this.showVideoCall = this.showVideoCall.bind(this);
		this.hideVideoCall = this.hideVideoCall.bind(this);
		this.state = {
			detail: this.props?.data,
			examinationId: this.props.examinationId,
			showAnamnesis: false,
			showVideoCall: false,
			appointmentDate: new Date(),
		};
	}

	componentDidMount() {
		console.log(this.props, " props");
		console.log(this.state.detail.AppointmentDate);
		console.log(new Date(this.state.detail.AppointmentDate * 1e3));
		this.setState({
			...this.state,
			appointmentDate: new Date(this.state.detail.AppointmentDate * 1e3),
		});
		//SinchIndex.demo2("b169e3a2-a239-45b8-8d6d-c8bd85b9dfd0", "XN8hAKUQdkitTpqGMW0kXQ==", "ecaa402a-7f8b-446f-97a3-adf70090b179");
	}
	showAnamnesis() {
		this.setState({
			showAnamnesis: true,
		});
	}
	hideAnamnesis() {
		this.setState({
			showAnamnesis: false,
		});
	}
	showVideoCall() {
		this.setState({
			showVideoCall: true,
		});
	}
	hideVideoCall() {
		this.setState({
			showVideoCall: false,
		});
	}
	render() {
		console.log(this.props);
		return (
			<div>
				<section className="app-detail">
					<Header />
					<div className="container">
						<input id="key" type="hidden" defaultValue="b169e3a2-a239-45b8-8d6d-c8bd85b9dfd0" />
						<input id="secret" type="hidden" defaultValue="XN8hAKUQdkitTpqGMW0kXQ==" />

						<div className="bread-crumb">
							<Link to="/">Anasayfa &gt;</Link> Bekleyen Randevu
						</div>
						<div className="body">
							<article>
								<div className="wrap">
									<img srcSet={this.state.detail?.ProfileImageFull} alt="" />
									<div className="informations">
										<div className="member">Üye</div>
										<div className="name">{this.state.detail.FullName}</div>
										{(() => {
											switch (this.state.detail.ExaminationType) {
												case 2:
													return (
														<div className="meeting btn-chat">
															<i className="icon-video-camera"></i>{" "}
															<span>Video Görüşme Talebi</span>
														</div>
													);
												case 3:
													return (
														<div className="meeting btn-chat">
															<i className="icon-chat"></i> <span>Soru Sor Görüşme Talebi</span>
														</div>
													);
												default:
													return null;
											}
										})()}
									</div>
								</div>
							</article>

							<article>
								<div className="row-wrap">
									<div className="info">
										<div className="status">
											<span className="property">Durum:</span>
											<span className="value text-green">
												<i className="icon-date"></i> <span>Bekleyen Randevu</span>
											</span>
											{/* <span className="value text-orange"><i className="icon-date"></i> <span>Bekleyen Randevu</span></span> */}
										</div>
										{!this.state.detail.ByAppointment ? (
											<div className="date-time">
												<div className="date">
													<span className="property">Randevu Tarihi:</span>{" "}
													<span className="value">
														{this.state.appointmentDate.getDate() +
															" " +
															monthNameList[this.state.appointmentDate.getMonth()] +
															" " +
															this.state.appointmentDate.getFullYear()}
													</span>
												</div>
												<div className="time">
													<span className="property">Saati:</span>{" "}
													<span className="value">
														{this.state.appointmentDate.toTimeString().substr(0, 5)}
													</span>
												</div>
											</div>
										) : (
											<div className="date-time">
												<div className="date">
													<span className="property">Randevu Tarihi:</span>{" "}
													<span className="value">Hemen</span>
												</div>
											</div>
										)}
									</div>
									<div className="button">
										<Link className="anamnesis" onClick={this.showAnamnesis}>
											Hastanın Anemnezini Gör &gt;
										</Link>
									</div>
								</div>
							</article>
							{this.state.detail.ExaminationStatus === 1 ? (
								<article>
									<div className="head">
										<div className="title">Tanısı</div>
										<div className="button">
											<button className="fade">
												<i className="icon-plus"></i> Tanı Koy
											</button>
										</div>
									</div>
									<div className="content"></div>
								</article>
							) : null}
							{this.state.detail.ExaminationStatus === 2 ? (
								<article>
									<div className="head">
										<div className="title">Tanısı</div>
										<div className="button">
											<button className="fade">
												<i className="icon-plus"></i> Tanı Koy
											</button>
										</div>
									</div>
									<div className="content"></div>
								</article>
							) : null}

							<article>
								<div className="title">Ödeme Bilgileri</div>
								<div className="fees">
									{}
									{this.state?.detail?.Payments?.map((item, index) => {
										return (
											<div className="row" key={index}>
												<span>Muayene Ücreti</span>
												<span>{item.Amount} TL</span>
											</div>
										);
									})}

									<hr className="line" />
									<div className="row">
										<span>Toplam</span>{" "}
										<span>
											{this?.state?.detail?.Payments?.map(item => item.Amount).reduce(
												(acc, amount) => acc + amount
											)}{" "}
											TL
										</span>
									</div>
								</div>
							</article>
						</div>

						<div className="side">
							{this.state.detail.ByAppopment}
							<div className="card">
								<div className="title">Talep Durumu</div>
								<div>
									<i className="icon-time"></i>{" "}
									<Timer RemainingSecond={this.state.detail.RemainingSecond} /> içerisinde
									onaylamalısınız!
								</div>
								<div className="examination-request-buttons">
									<div className="accept">
										<button
											onClick={() => {
												ExaminationService.acceptExamination(this.state.examinationId);
											}}
										>
											Kabul Et
										</button>
									</div>
									<div className="reject">
										<button
											onClick={() => {
												ExaminationService.rejectExamination(this.state.examinationId);
											}}
										>
											Reddet
										</button>
									</div>
								</div>
							</div>
							{this.state.detail.ExaminationStatus > 1 ? (
								<div className="card">
									<div className="title">İşlem</div>

									<div className="row">
										<i className="icon-video-camera"></i>
										<button className="btn-video fade ddd" onClick={this.showVideoCall}>
											Görüntülü Ara
										</button>
									</div>

									<div className="row">
										<i className="icon-chat"></i>
										<button className="btn-chat fade">Sohbet</button>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</section>
				{this.state.showAnamnesis ? (
					<AnamnesisModal
						anamnesis={this.state.detail.Anamnesis}
						show={this.state.showAnamnesis}
						hideFunction={this.hideAnamnesis}
					/>
				) : null}
				{this.state.showVideoCall ? (
					<VideoCallModal
						src={
							"https://api.drpir.com/call/" +
							this.state.detail.HealthProfessionalId +
							"/" +
							this.state.detail.CustomerId
						}
						hideFunction={this.hideVideoCall}
					/>
				) : null}
			</div>
		);
	}
}
