import React, { Component } from 'react'
import Loader from '../Loader';
import Timer from "../Timer";
import api from '../../services/api';
import { Link } from 'react-router-dom';
    
export default class ExaminationCard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    examinationStatus() {
    switch(this?.props?.ExaminationType) {
                case 2: {
                    return <div className="meeting"><i className="icon-video-camera"></i> <Link to="/">Görüntülü Muayene Talebi</Link></div>
                }
                case 3: {
                    return <div className="meeting"><i className="icon-chat"></i> <Link to="/">Yazılı Muayene Talebi</Link></div>
                }
                default: return <div></div>
            }
    }

    render() {
        return <article>
                    <div className="head">
                        <div className="user-info">
                            <div className="wrap">
                                <img srcSet={this.props.ProfileImageFull} alt={this.props.FullName} />
                                <div className="informations">
                                    <div className="member"><img srcSet={this.props.StatusIcon} alt='' height="20"/> {this.props.StatusTitle}</div>
                                    <div className="name">{this.props.FullName}</div>
                                    { this.examinationStatus() }
                                </div>
                            </div>
                        </div>
                        <div className="app-info">
                        {
                            this?.props?.ByAppointment
                            ? <div className="app">Randevu</div>
                            : <div className="app">Hemen</div>
                        }
                            
                        </div>
                    </div>
                    {
                        this?.props?.ByAppointment
                        ? <div className="footer">
                            <div className="time">
                                <i className="icon-date"></i> Randevu Tarihi: {this?.props?.AppointmentDateText + " " + this?.props?.AppointmentDateTime}
                            </div>
                            <div className="button">
                                <Link to={ "/examination/" + this?.props?.ExaminationId} className="detail" >Muayene Detay &gt;</Link>
                            </div>
                        </div>
                        : <div className="footer">
                            <div className="time">
                                <i className="icon-time"></i> <Timer RemainingSecond={this?.props?.RemainingSecond} /> &nbsp; sn içinde Kabul Etmelisiniz
                            </div>
                            <div className="button">
                                <Link to={ "/examination/" + this?.props?.ExaminationId} className="anamnesis" onClick={() => console.log(this?.props?.ExaminationId)} >Talep Detay &gt;</Link>
                            </div>
                        </div>
                    }
                    
                </article>

    }
}
