import React, { Component } from 'react'
import Header from './Header'
import Footer from "./Footer"
import PageLoader from "./PageLoader";
import api from '../services/api';

class Profile extends Component {
    state = {
        isPageLoader: true,
        user: {},
    }
    componentDidMount(){
        api.post('/api/GetDashboard?timestamp=' + new Date().getTime(), {})
        .then(resp=> {
           
            this.setState({
                isPageLoader:false,
                user: resp.data
            })
            
            console.log(resp.data)
        })
        .catch(function (error) {
            
        });
    }
    showTab = ()=>{
        
    }
    render() {
        return (
            this.state.isPageLoader 
            ? <PageLoader />
            : <main className="clear-after overflow-hidden">
                <Header content={<div className='nav-header-page'></div>}/>
                <section className="doctor-profile">
                <div className="container">
                    <div className="body">
                        <article>
                            <div className="wrap">
                                <img srcSet={this.state.user.ProfileImage} alt={this.state.user.FirstName + " " + this.state.user.LastName} />
                                <div className="informations">
                                    <div className="name">
                                       {this.state.user.Title} {this.state.user.FirstName + " " + this.state.user.LastName}
                                    </div>
                                    <div className="title">
                                    {this.state.user.Master}
                                    </div>
                                    <div className="points">
                                        <i className="icon-star"></i>
                                        <span className="point">{this.state.user.AverageScore}</span>
                                        <span className="point-count">{this.state.user.Scores.length} Değerlendirme</span>
                                    </div>
                                </div>
                            </div>
                        </article>

                        <article>
                            <div className="tab-nav">
                                <ul>
                                    <li data-tab-target="#about" className="active">Hakkında</li>
                                    <li data-tab-target="#comments">Değerlendirmeler</li>
                                </ul>
                            </div>

                            <div className="tab-content">
                                <div id="about" className="tab-article active" data-tab-content>
                                    <div className="text">
                                        {this.state.user.AboutText}
                                    </div>
                                    <div className="history">
                                        <div className="row">
                                            <div className="title">Üniversite</div>
                                            <div className="text"><span>{this.state.user.UniversityName}</span></div>
                                        </div>
                                        <div className="row">
                                            <div className="title">Kurumlar</div>
                                            <div className="text">
                                                {
                                                    this.state.user.Careers.map((item, index)=>{
                                                        return(
                                                            <span key={index}>{index !== 0 ? <span>,</span>: null} {item}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="comments" className="tab-article" data-tab-content>
                                    <div className="header">
                                        <div className="title">Hakkınızdaki Değerlendirmeler
                                            <span>Hastalarınız hakkınızdaki görüşleri ve şikayetleri</span>
                                        </div>

                                        <div className="rating">
                                            <div className="stars">
                                                <i className={"icon-star " + this.state.user.AverageScore > 0 ? "fill" : "null"}></i>
                                                <i className={"icon-star " + this.state.user.AverageScore > 1 ? "fill" : "null"}></i>
                                                <i className={"icon-star " + this.state.user.AverageScore > 2 ? "fill" : "null"}></i>
                                                <i className={"icon-star " + this.state.user.AverageScore > 3 ? "fill" : "null"}></i>
                                                <i className={"icon-star " + this.state.user.AverageScore > 4 ? "fill" : "null"}></i>
                                                <span className="point">{this.state.user.AverageScore}</span>
                                            </div>
                                            <div className="count">
                                                {this.state.user.Scores.length} Değerlendirme
                                            </div>
                                        </div>
                                    </div>
                                    <div className="comments">
                                        <div className="row">
                                            <div className="image">
                                                <img src="images/users/user-3.png" alt="Kullanıcı" />
                                            </div>
                                            <div className="comment-text">
                                                <div className="name">Seh** B**</div>
                                                <div className="text">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo fuga magnam corrupti necessitatibus nulla explicabo, amet veniam molestias voluptatibus? Ullam.</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="image">KC</div>
                                            <div className="comment-text">
                                                <div className="name">Ka** C**</div>
                                                <div className="text">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo fuga magnam corrupti necessitatibus nulla explicabo, amet veniam molestias voluptatibus? Ullam.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>

                    <div className="side">
                        <div className="card">
                            <div className="title">Muayene Kabul Ayarları</div>
                            <div className="status">
                                <div className="title">
                                    Muayene Kabul
                                </div>
                                <div className="btn-switch-blue">
                                    <label className="switch">
                                        <input type="checkbox" className="check" checked />
                                        <span className="slider"></span>
                                        <span className="text"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="settings">
                                <div className="title">Muayene Görüşme Türü Ayarları</div>
                                <div className="meeting">
                                    <div className="text"><i className="icon-video-camera"></i> Görüntülü görüşme kabul et</div>
                                    <div className="btn-switch">
                                        <label className="switch">
                                            <input type="checkbox" checked />
                                            <span className="slider"></span>
                                            <span className="text"></span>
                                        </label>
                                    </div>
                                </div>

                                <hr className="line" />

                                <div className="meeting">
                                    <div className="text"><i className="icon-chat"></i> Sohbet görüşme kabul et</div>
                                    <div className="btn-switch">
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider"></span>
                                            <span className="text"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                <Footer isLogin={false}/>
            </main>
        )
    }
}
export default Profile;