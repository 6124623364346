import axios from "axios";
import TokenService from "./token.service";

var authorization = 'BASIC RE9UTkVUOkVFRjQ3RDlBLURCQTktNEQwMi1CN0IwLTA0RjQyNzlBNkQyMA==';
var baseURL= "//api.drpir.com";
const instance = axios.create({
  baseURL: baseURL,
  //baseURL: "https://localhost:44345",
  headers: {
    'Content-Type':'application/json',
    'Authorization': authorization
  },
});
instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["Authorization"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  }, 
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/token" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          var headers2 = {
            'Content-Type':'application/x-www-form-urlencoded',
            'Authorization': authorization
          }
          const rtoken = TokenService.getLocalRefreshToken();

          var data = {};
          data.refresh_token = rtoken;
          data.grant_type = "refresh_token";
          data.device_id = "chrome";
          data.device_name = "chrome";
          data.device_type = "pc";
          data.device_model = "chrome";
          data.platform = "web";
          data.culture = "2";

          //var postData = JSON.stringify({data});
          const postData = new URLSearchParams(data).toString();
          const rs = await axios.post( baseURL + "/token", postData, {headers: headers2});
          const { access_token, token_type } = rs.data;
          
          TokenService.updateLocalAccessToken(token_type, access_token);
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);
export default instance;