import React, { Component } from 'react'
import Header from "./Header"
import Footer from "./Footer"
import { Link } from 'react-router-dom';
import ActiveExaminationRequests from './ExaminatinsTab/ActiveExaminationRequests';
import CompletedExaminations from './ExaminatinsTab/CompletedExaminations';
import CanceledExaminations from './ExaminatinsTab/CanceledExaminations';
import ActiveExaminations from './ExaminatinsTab/ActiveExaminations';

class Dashboard extends Component {
    render() {
    
        return (
            <main className="clear-after">
                <Header content={
                    <div className="nav-header">
                        <div className="container">
                            <div className="title">
                                <h1>GELEN MUAYENE TALEPLERİ</h1>
                            </div>
                            <div className="tab-nav">
                                <ul>
                                    <li data-tab-target="#active" className="text-primary active">Aktif</li>
                                    <li data-tab-target="#pending" className="text-orange">Bekleyen Randevu</li>
                                    <li data-tab-target="#cancel" className="text-dark-grey">İptal Edilen Randevu</li>
                                    <li data-tab-target="#complete" className="text-green">Tamamlanmış Randevu</li>
                                </ul>
                            </div>
                            <div className="tab-dropdown-nav">
                                <div className="select-item">
                                    <span data-tab-target="#active" className="select-text text-primary">Aktif</span>
                                    <i className="icon-arrow-down"></i>
                                </div>
                                <ul className="list hide">
                                    <li data-tab-target="#active" className="item text-primary">Aktif</li>
                                    <li data-tab-target="#pending" className="item text-orange">Bekleyen Randevu</li>
                                    <li data-tab-target="#cancel" className="item text-dark-grey">İptal Edilen Randevu</li>
                                    <li data-tab-target="#complete" className="item text-green">Tamamlanmış Randevu</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }/>
                
                <section className="inspection-requests">
                    <div className="container">
                        <div className="body">
                            <div  id="active" className="tab-article" data-tab-content>
                                <ActiveExaminations/>
                            </div>

                            <div id="pending" className="tab-article" data-tab-content>
                                <ActiveExaminationRequests/>
                            </div>

                            <div id="cancel" className="tab-article" data-tab-content>
                                <CanceledExaminations/>                             
                            </div>

                            <div id="complete" className="tab-article" data-tab-content>
                                <CompletedExaminations/>
                            </div>
                        </div>

                        <div className="side">
                            <div className="card">
                                <div className="wrap">
                                    <img src="images/users/user.png" alt="Op.Dr. Yeşim Ballıgız" />
                                    <div className="info">
                                        <div className="name">
                                            Op.Dr. Yeşim Ballıgız
                                        </div>
                                        <div className="title">
                                            Aile Hekimi
                                        </div>
                                        <div className="value">
                                            <i className="icon-star"></i> <span>4.5</span> 22 Değerlendirme
                                        </div>
                                    </div>
                                </div>

                                <hr className="line" />

                                <div className="status">
                                    <div className="title">
                                        Durum
                                    </div>
                                    <div className="text">
                                        Muayene <span>Açık</span>
                                    </div>
                                </div>

                                <hr className="line" />

                                <div className="settings">
                                    <div className="title">Muayene Görüşme Türü Ayarları</div>
                                    <div className="meeting">
                                        <div className="text"><i className="icon-chat"></i> Sohbet Görüşmesi</div> <span>Aktif</span>
                                    </div>

                                    <hr className="line" />

                                    <div className="meeting">
                                        <div className="text"><i className="icon-video-camera"></i> Görüntülü Görüşme</div>
                                        <span>Aktif</span>
                                    </div>
                                </div>
                                <hr/>
                                <Link to="/settings" className="peak-settings"><i className="icon-edit"></i> Muayene Ayarları</Link>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="request-popup">
                    <div className="history">
                        <div className="close">Kapat</div>
                
                        <div className="title">Hastalık Geçmişi</div>
                
                        <div className="row">
                            <span className="property">Hastalık Başlık:</span>
                            <span>Kovid</span>
                        </div>
                
                        <div className="row">
                            <span className="property">Konulan Tanı: </span><br/>
                            <span>Baş Ağrısı</span>
                        </div>
                
                        <div className="row">
                            <span className="property">Ameliyat Geçmişiniz: </span><br/>
                            <span>Yok</span>
                        </div>
                
                        <div className="row">
                            <span className="property">Tanı Tarihi: </span>
                            <span>10.10.2021</span>
                        </div>
                
                        <div className="row">
                            <span className="property">Açıklama: </span><br/>
                            <span>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Possimus quas rem nostrum necessitatibus cumque atque dolorem, a magnam at
                                impedit.
                            </span>
                        </div>
                    </div>
                
                    <div className="container">
                        <i className="icon-close close"></i>
                        <div className="content">
                            <h1>GELEN MUAYENE TALEPLERİ</h1>
                            <hr className="line" />
                            <div className="head">
                                <div className="user-info">
                                    <div className="wrap">
                                        <img src="images/users/user-2.png" alt="Yeşim Usta" />
                                        <div className="informations">
                                            <div className="member">Üye:</div>
                                            <div className="name">Yeşim Usta</div>
                                            <div className="meeting">
                                                <i className="icon-chat"></i> <Link to="/examination">Soru Sor Görüşme Talebi</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="app-info">
                                    <div className="app">Randevu</div>
                                    <p>Hemen</p>
                                </div>
                            </div>
                
                            <hr className="line"/>
                
                            <table className="table">
                                <thead>

                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={5}>
                                            <span className="row-title">Hastanın İlettiği Anemnez</span>
                                        </td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td colSpan="2">
                                            <span className="property">Hasta Yakını:</span>
                                            <span className="value">Arkadaşı</span>
                                        </td>
                                        <td colSpan="3">
                                            <span className="property">Hastanın Adı Soyadı:</span>
                                            <span className="value">Berrak Samlı</span>
                                        </td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td width="20%"><span className="property">Cinsiyet:</span>
                                            <span className="value">Erkek</span>
                                        </td>
                                        <td width="20%"><span className="property">Yaş:</span>
                                            <span className="value">32</span>
                                        </td>
                                        <td width="20%"><span className="property">Kilo:</span>
                                            <span className="value">85</span>
                                        </td>
                                        <td width="20%"><span className="property">Boy:</span>
                                            <span className="value">175</span>
                                        </td>
                                        <td width="20%"><span className="property">Kan Grubu:</span>
                                            <span className="value">0rh+</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span className="row-title">Hastanın Şikayeti</span></td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td colSpan="5">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente iusto quasi natus nam
                                                dicta repellat ea at odit hic molestias eos blanditiis quidem neque, dolore deserunt
                                                voluptatum debitis. Optio, nihil.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5">
                                            <span className="row-title">Alışkanlıklar</span>
                                        </td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td colSpan="5">
                                            <span className="item"><i className="icon-check"></i> Sigara</span>
                                            <span className="item"><i className="icon-check"></i> Alkol</span>
                                            <span className="item"><i className="icon-check"></i> Uyuşturucu</span>
                                            <span className="item"><i className="icon-check"></i> Diğer Kolonya</span>
                                            <span className="item">Yok</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5">
                                            <span className="row-title">Alerji Hikayesi</span>
                                        </td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td colSpan="5">
                                            <p>Yok</p>
                                        </td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td colSpan="5">
                                            <span className="row-title">1. Derece Akrabalarında Kronik Hastalıklar</span>
                                        </td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td colSpan="5">
                                            <span className="item"><i className="icon-check"></i> Diabet</span>
                                            <span className="item"><i className="icon-check"></i> Hiper Tansiyon</span>
                                            <span className="item"><i className="icon-check"></i> Kalp Hastalıkları</span>
                                            <span className="item"><i className="icon-check"></i> Kalp Hastalıkları</span>
                                            <span className="item">Yok</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5">
                                            <span className="row-title">Geçirilmiş Hastalıklar</span>
                                        </td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td colSpan="5">
                                            {/* <!-- <span className="item"><i className="icon-check"></i> Hastalık</span> --> */}
                                            <span className="item">Yok</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5">
                                            <span className="row-title">Hastalık Geçmişi</span>
                                        </td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td colSpan="5">
                                            <span className="item rounded-item history-item">Kovid <i className="icon-right-arrow"></i></span>
                                            <span className="item rounded-item history-item">Boğaz Şişmesi <i
                                                    className="icon-right-arrow"></i></span>
                                            <span className="item rounded-item history-item">Kalp Damar <i
                                                    className="icon-right-arrow"></i></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5">
                                            <span className="row-title">Geçirilmiş Ameliyatlar</span>
                                        </td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td colSpan="5">
                                            {/* <!-- <span className="item"><i className="icon-check"></i> Ameliyat</span> --> */}
                                            <span className="item">Yok</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5">
                                            <span className="row-title">Hastahanede Yatma Öyküsü</span>
                                        </td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td colSpan="5">
                                            <p>Yok</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5">
                                            <span className="row-title">Sürekli Kullandığı İlaçlar</span>
                                        </td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td colSpan="5">
                                            <span className="item">Parol</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <span className="property">Meslek:</span>
                                            <span className="value">Belirtilmemiş</span> <br/>
                                            <span className="property">Telefonu:</span>
                                            <span className="value">0533 920 00 00</span>
                                        </td>
                                        <td colSpan="3">
                                            <span className="property">Adres:</span>
                                            <span className="value">Küçükbakkalköy Mah. Kayışdağı Cad. No:37 Ataşehir/İstanbul</span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot></tfoot>
                            </table>
                        </div>
                    </div>
                
                    <div className="footer">
                        <div className="time">
                            <i className="icon-time"></i> <span>2:00</span> &nbsp; saniye içinde Kabul Etmelisiniz
                        </div>
                        <div className="buttons">
                            <button>Kabul Et</button>
                            <button className="close">Red Et</button>
                        </div>
                    </div>
                </div>
                <Footer isLogin={false}/>

            </main>
        )
    }
}
export default Dashboard;