import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import Timer from "../Timer";
import api from "../../services/api";

import ExaminationCard from "./ExaminationCard";

function ActiveExaminationRequests() {
	const [data, setData] = useState({
		isPageLoader: true,
		items: [],
	});

	useEffect(() => {
		api.post("/api/DoctorActiveExaminationRequestList", {}).then(resp => {
			console.log(resp);
			setData({
				isPageLoader: false,
				items: resp?.data,
			});
		});
	}, []);

	console.log(data)

	return data?.isPageLoader ? (
		<section className="container-box ptb-100">loading</section>
	) : (
		<div>
			<div className="title text-orange">Onay Bekleyen</div>
			{data?.items?.map(function (exam, index) {
				return <ExaminationCard key={index} {...exam} />;
			})}
		</div>
	);
}

export default ActiveExaminationRequests;
